/* eslint-disable react/prop-types */
import  { createContext, useContext, useState } from 'react';

// Create context
const EscrowFiltersContext = createContext();

// Create provider
export const EscrowFiltersProvider = ({ children }) => {
  const [filters, setFilters] = useState({
    searchQuery: '',
    startDate: null,
    endDate: null,
    buyCurrency: null,
    sellCurrency: null,
  });

  const updateFilters = (newFilters) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  return (
    <EscrowFiltersContext.Provider value={{ filters, updateFilters }}>
      {children}
    </EscrowFiltersContext.Provider>
  );
};

// Custom hook to use filters
export const useEscrowFilters = () => {
  return useContext(EscrowFiltersContext);
};
