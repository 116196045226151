import "./pairprices.scss";
import { Check, Loader2, Pencil, X, Search } from "lucide-react";
import ReactSelect from "react-select";
import { useRates } from "../../../providers/Rates/RatesProvider";
import _ from "lodash";
import { useEffect, useState } from "react";
import Big from "big.js";
import { useAuth } from "../../../providers/AuthProvider";
import { formatAmount } from "../../../common/formatAmount";
import Switch from "rc-switch";

const reactSelectStylePairPrices = {
  control: (baseStyles) => ({
    ...baseStyles,
    fontSize: ".9rem",
    fontWeight: "600",
    padding: ".2rem",
    borderRadius: "8px",
    width: "100%",
    color: "#172c50",
    backgroundColor: "#ffffff",
    borderColor: "#3E9CF3",
  }),
};

const PairPrices = () => {
  const {
    isLoadingPairPrices,
    pairprices,
    currencies,
    isLoadingRates,
    rates,
    isSavingPairPrice,
    savePairPrice,
  } = useRates();
  const [editedPairPrices, setEditedPairPrices] = useState({});
  const [pairPriceState, setPairPriceState] = useState({
    currencyOptions: [],
    toCurrencyFilter: [],
    fromCurrencyFilter: [],
    searchQuery: "",
  });
  const { authTokens } = useAuth();

  useEffect(() => {
    const newPairPriceState = { ...pairPriceState };
    // build currency options
    const currencyOptions = currencies.map((currency) => ({
      value: currency.code,
      label: currency.code,
    }));
    newPairPriceState.currencyOptions = currencyOptions;

    setPairPriceState(newPairPriceState);
  }, []);

  const handleToCurrencyChange = (evt) => {
    let toCurrencyFilter = [];
    if (!_.isEmpty(evt)) {
      const currency = evt.value;
      toCurrencyFilter = [`${currency}`];
    }

    const newPairPriceState = { ...pairPriceState };
    newPairPriceState.toCurrencyFilter = toCurrencyFilter;
    setPairPriceState(newPairPriceState);
  };

  const handleSearchChange = (e) => {
    const newPairPriceState = { ...pairPriceState };
    newPairPriceState.searchQuery = e.target.value.toLowerCase();
    setPairPriceState(newPairPriceState);
  };

  const handleFromCurrencyChange = (evt) => {
    let fromCurrencyFilter = [];
    if (!_.isEmpty(evt)) {
      const currency = evt.value;
      fromCurrencyFilter = [`${currency}`];
    }

    const newPairPriceState = { ...pairPriceState };
    newPairPriceState.fromCurrencyFilter = fromCurrencyFilter;
    setPairPriceState(newPairPriceState);
  };

  const filteredPairPrices = pairprices.filter((pairprice) => {
    const { fromCurrencyFilter, toCurrencyFilter, searchQuery } =
      pairPriceState;
    const matchesFromCurrency =
      fromCurrencyFilter.length === 0 ||
      fromCurrencyFilter.includes(pairprice.from.code);
    const matchesToCurrency =
      toCurrencyFilter.length === 0 ||
      toCurrencyFilter.includes(pairprice.to.code);

    const matchesSearchQuery =
      pairprice.from.code.toLowerCase().includes(searchQuery) ||
      pairprice.to.code.toLowerCase().includes(searchQuery) ||
      pairprice.referencePrice.toString().includes(searchQuery) ||
      pairprice.markdown.toString().includes(searchQuery) ||
      pairprice.markup.toString().includes(searchQuery) ||
      pairprice.buyPrice.toString().includes(searchQuery) ||
      pairprice.sellPrice.toString().includes(searchQuery);

    return matchesFromCurrency && matchesToCurrency && matchesSearchQuery;
  });

  // Initialize editedPairPrices
  useEffect(() => {
    if (!_.isEmpty(pairprices)) {
      const newEditedPairPrices = {};
      pairprices.forEach((pairprice) => {
        const newPairPrice = _.clone(pairprice);
        newPairPrice.saving = false;
        newPairPrice.editing = false;
        newEditedPairPrices[pairprice.pair] = newPairPrice;
      });
      setEditedPairPrices(newEditedPairPrices);
    }
  }, [pairprices]);

  //Set all editedPairPrices saving to false when isSavingPairPrice switches to false
  useEffect(() => {
    if (!isSavingPairPrice) {
      if (!_.isEmpty(editedPairPrices)) {
        const newEditedPairPrices = {};
        Object.keys(editedPairPrices).forEach((pair) => {
          const editedPairPrice = editedPairPrices[pair];
          editedPairPrice.saving = false;
          newEditedPairPrices[pair] = editedPairPrice;
        });
        setEditedPairPrices(newEditedPairPrices);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSavingPairPrice]);

  const isBooting = () => {
    return (
      isLoadingPairPrices ||
      _.isEmpty(pairprices) ||
      isLoadingRates ||
      _.isEmpty(rates.rates)
    );
  };

  const editPairPrice = (pairprice) => {
    const editedPairPrice = editedPairPrices[pairprice.pair];
    editedPairPrice.editing = true;
    setEditedPairPrices({
      ...editedPairPrices,
      [pairprice.pair]: editedPairPrice,
    });
  };

  const editingPairPrice = (pairprice) => {
    return (
      !_.isEmpty(editedPairPrices[pairprice.pair]) &&
      editedPairPrices[pairprice.pair].editing
    );
  };

  const savingPairPrice = (pairprice) => {
    return (
      !_.isEmpty(editedPairPrices[pairprice.pair]) &&
      editedPairPrices[pairprice.pair].saving
    );
  };

  const cancelEditPairPrice = (pairprice) => {
    const oldPairPrice = _.find(pairprices, { pair: pairprice.pair });
    oldPairPrice.saving = false;
    oldPairPrice.editing = false;
    setEditedPairPrices({
      ...editedPairPrices,
      [pairprice.pair]: oldPairPrice,
    });
  };

  const markdownChanged = ({ pairprice, markdown }) => {
    const editedPairPrice = editedPairPrices[pairprice.pair];
    editedPairPrice.markdown = markdown;

    if (!_.isEmpty(markdown)) {
      //Get new buy price
      const refPrice = pairprice.referencePrice;
      const newBuyPrice = Big(refPrice)
        .sub(Big(refPrice).mul(Big(markdown).div(Big(100))))
        .toString();
      editedPairPrice.buyPrice = newBuyPrice;
    }

    setEditedPairPrices({
      ...editedPairPrices,
      [pairprice.pair]: editedPairPrice,
    });
  };

  const getMarkdown = (pairprice) => {
    let markdown = pairprice.markdown;
    const editedPairPrice = editedPairPrices[pairprice.pair];
    if (!_.isEmpty(editedPairPrice)) {
      markdown = editedPairPrice.markdown;
    }
    return formatAmount(markdown);
  };

  const markupChanged = ({ pairprice, markup }) => {
    const editedPairPrice = editedPairPrices[pairprice.pair];
    editedPairPrice.markup = markup;

    if (!_.isEmpty(markup)) {
      //Get new sell price
      const refPrice = pairprice.referencePrice;
      const newSellPrice = Big(refPrice)
        .add(Big(refPrice).mul(Big(markup).div(Big(100))))
        .toString();
      editedPairPrice.sellPrice = newSellPrice;
    }

    setEditedPairPrices({
      ...editedPairPrices,
      [pairprice.pair]: editedPairPrice,
    });
  };

  const getMarkup = (pairprice) => {
    let markup = pairprice.markup;
    const editedPairPrice = editedPairPrices[pairprice.pair];
    if (!_.isEmpty(editedPairPrice)) {
      markup = editedPairPrice.markup;
    }
    return formatAmount(markup);
  };

  const buyPriceChanged = ({ pairprice, buyPrice }) => {
    const editedPairPrice = editedPairPrices[pairprice.pair];
    editedPairPrice.buyPrice = buyPrice;

    if (!_.isEmpty(buyPrice)) {
      //Get new markdown
      const refPrice = pairprice.referencePrice;
      const newMarkdown = Big(100)
        .mul(Big(Big(refPrice).sub(Big(buyPrice))).div(Big(refPrice)))
        .toString();
      editedPairPrice.markdown = newMarkdown;
    }

    setEditedPairPrices({
      ...editedPairPrices,
      [pairprice.pair]: editedPairPrice,
    });
  };

  const getBuyPrice = (pairprice) => {
    let buyPrice = pairprice.buyPrice;
    const editedPairPrice = editedPairPrices[pairprice.pair];
    if (!_.isEmpty(editedPairPrice)) {
      buyPrice = editedPairPrice.buyPrice;
    }
    return formatAmount(buyPrice);
  };

  const sellPriceChanged = ({ pairprice, sellPrice }) => {
    const editedPairPrice = editedPairPrices[pairprice.pair];
    editedPairPrice.sellPrice = sellPrice;

    if (!_.isEmpty(sellPrice)) {
      //Get new markdown
      const refPrice = pairprice.referencePrice;
      const newMarkup = Big(100)
        .mul(Big(Big(sellPrice).sub(Big(refPrice))).div(Big(refPrice)))
        .toString();
      editedPairPrice.markup = newMarkup;
    }

    setEditedPairPrices({
      ...editedPairPrices,
      [pairprice.pair]: editedPairPrice,
    });
  };

  const getSellPrice = (pairprice) => {
    let sellPrice = pairprice.sellPrice;
    const editedPairPrice = editedPairPrices[pairprice.pair];
    if (!_.isEmpty(editedPairPrice)) {
      sellPrice = editedPairPrice.sellPrice;
    }
    return formatAmount(sellPrice);
  };

  const enabledChanged = ({ pairprice, enabled }) => {
    const editedPairPrice = editedPairPrices[pairprice.pair];
    editedPairPrice.enabled = enabled;
    setEditedPairPrices({
      ...editedPairPrices,
      [pairprice.pair]: editedPairPrice,
    });
  };

  const getEnabled = (pairprice) => {
    let enabled = pairprice.enabled;
    const editedPairPrice = editedPairPrices[pairprice.pair];
    if (!_.isEmpty(editedPairPrice)) {
      enabled = editedPairPrice.enabled;
    }
    return enabled;
  };

  const updatePairPrice = (pairprice) => {
    const editedPairPrice = editedPairPrices[pairprice.pair];
    const token = authTokens.IdToken;
    const pair = pairprice.pair;
    const markup = editedPairPrice.markup;
    const markdown = editedPairPrice.markdown;
    const enabled = editedPairPrice.enabled;

    savePairPrice({ token, pair, markup, markdown, enabled });
    editedPairPrice.saving = true;
    editedPairPrice.editing = false;
    setEditedPairPrices({
      ...editedPairPrices,
      [pairprice.pair]: editedPairPrice,
    });
  };

  return (
    <div className="pair_prices_page">
      <div className="heading">
        <div className="title">Pair Prices</div>
      </div>
      <div className="search_inputs">
	  <div className="search_input currency_picker_container">
          {/* <div className="label">Currency Pair</div> */}
          <div className="input currency">
            <ReactSelect
              className="currency_picker"
              styles={reactSelectStylePairPrices}
              options={pairPriceState.currencyOptions}
              isClearable={true}
              onChange={handleFromCurrencyChange}
            />
            <ReactSelect
              className="currency_picker"
              styles={reactSelectStylePairPrices}
              options={pairPriceState.currencyOptions}
              isClearable={true}
              onChange={handleToCurrencyChange}
            />
          </div>
        </div>
        {/**<div className="search_input search">
          <div className="label">Search</div>
          <div className="input search_query_container">
            <input
              className="search_query"
              type="text"
              placeholder="Search for currency, prices... etc"
              onChange={handleSearchChange}
            />
            <Search className="icon" />
          </div>
        </div> */}
      
      </div>

      {isBooting() && (
        <div className="pair_prices_loader_container">
          Loading pair prices...
          <Loader2 className="pair_prices_loader" />
        </div>
      )}
      {!isBooting() && (
        <>
          <table className="pair_prices_table">
            <thead>
              <tr>
                <th>Pair</th>
                <th className="reference_price">Ref. price</th>
                <th className="markdown">Markdown %</th>
                <th className="markup">Markup %</th>
                <th className="buy_price">Buy Price</th>
                <th className="sell_price">Sell price</th>
                <th className="enabled">Enabled</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {_.sortBy(filteredPairPrices, ["to.code"]).map(
                (pairprice, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <div className="pairpriceInfo">
                          <div className="logos">
                            <img
                              className="logo from"
                              src={pairprice.from.logo}
                              alt=""
                            />
                            <img
                              className="logo to"
                              src={pairprice.to.logo}
                              alt=""
                            />
                          </div>
                          <div className="codes">
                            1 {pairprice.from.code} - {pairprice.to.code}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="reference_price">
                          {formatAmount(pairprice.referencePrice)}{" "}
                          {pairprice.to.code}
                        </div>
                      </td>
                      <td>
                        <div className="markdown">
                          {/* If we are not editing a pairprice */}
                          {!editingPairPrice(pairprice) && (
                            <>{getMarkdown(pairprice)}</>
                          )}

                          {/* If we are editing a pairprice */}
                          {editingPairPrice(pairprice) && (
                            <input
                              type="number"
                              value={editedPairPrices[pairprice.pair].markdown}
                              onChange={(e) =>
                                markdownChanged({
                                  pairprice,
                                  markdown: e.target.value,
                                })
                              }
                            />
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="markup">
                          {/* If we are not editing a pairprice */}
                          {!editingPairPrice(pairprice) && (
                            <>{getMarkup(pairprice)}</>
                          )}

                          {/* If we are editing a pairprice */}
                          {editingPairPrice(pairprice) && (
                            <input
                              type="number"
                              value={editedPairPrices[pairprice.pair].markup}
                              onChange={(e) =>
                                markupChanged({
                                  pairprice,
                                  markup: e.target.value,
                                })
                              }
                            />
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="buy_price">
                          {/* If we are not editing a pairprice */}
                          {!editingPairPrice(pairprice) && (
                            <>{getBuyPrice(pairprice)}</>
                          )}
                          {/* If we are editing a pairprice */}
                          {editingPairPrice(pairprice) && (
                            <input
                              type="number"
                              value={editedPairPrices[pairprice.pair].buyPrice}
                              onChange={(e) =>
                                buyPriceChanged({
                                  pairprice,
                                  buyPrice: e.target.value,
                                })
                              }
                            />
                          )}{" "}
                          {pairprice.to.code}
                        </div>
                      </td>
                      <td>
                        <div className="sell_price">
                          {/* If we are not editing a pairprice */}
                          {!editingPairPrice(pairprice) && (
                            <>{getSellPrice(pairprice)}</>
                          )}
                          {/* If we are editing a pairprice */}
                          {editingPairPrice(pairprice) && (
                            <input
                              type="number"
                              value={editedPairPrices[pairprice.pair].sellPrice}
                              onChange={(e) =>
                                sellPriceChanged({
                                  pairprice,
                                  sellPrice: e.target.value,
                                })
                              }
                            />
                          )}{" "}
                          {pairprice.to.code}
                        </div>
                      </td>
                      <td>
                        <Switch
                          checked={getEnabled(pairprice)}
                          disabled={!editingPairPrice(pairprice)}
                          onChange={(checked) =>
                            enabledChanged({ pairprice, enabled: checked })
                          }
                        />
                      </td>
                      <td>
                        <div className="pair_price_controls">
                          {/* If we are not editing a pair price and not saving*/}
                          {!editingPairPrice(pairprice) &&
                            !savingPairPrice(pairprice) && (
                              <Pencil
                                className="edit pair_price_control"
                                onClick={() => editPairPrice(pairprice)}
                              />
                            )}

                          {/* If we are editing a pair price */}
                          {editingPairPrice(pairprice) &&
                            !savingPairPrice(pairprice) && (
                              <>
                                <Check
                                  className="save_edit pair_price_control"
                                  onClick={() => updatePairPrice(pairprice)}
                                />
                                <X
                                  className="cancel_edit pair_price_control"
                                  onClick={() => cancelEditPairPrice(pairprice)}
                                />
                              </>
                            )}

                          {/* If we are saving a pair price */}
                          {savingPairPrice(pairprice) && (
                            <Loader2 className="saving_pair_price_loader pair_price_control" />
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
export default PairPrices;
