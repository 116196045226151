/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable react-refresh/only-export-components */
import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useReducer } from "react";
import api from "../../../api";
import { useAuth } from "../../../providers/AuthProvider";
import _ from "lodash";

const EscrowCompletedContext = createContext();
const EscrowCompletedDispatchContext = createContext();

export const ESCROW_COMPLETED_ACTIONS = {
	updateState: "updateState",
	updateStates: "updateStates",
};

export const EscrowCompletedProvider = ({ children }) => {
	const { authTokens } = useAuth();

	const getEscrowCompleted = useQuery({
		queryKey: ["escrowCompleted"],
		queryFn: () => {
			return api.getEscrowCompleted({ token: authTokens.IdToken });
		},
		enabled: false,
	});

	const fetchEscrowCompleted = async () => {
		escrowCompletedDispatch({ type: ESCROW_COMPLETED_ACTIONS.updateState, key: "isFetchingEscrowCompleted", value: true });
		const escrowCompletedRefetchResult = await getEscrowCompleted.refetch();
		escrowCompletedDispatch({ type: ESCROW_COMPLETED_ACTIONS.updateState, key: "isFetchingEscrowCompleted", value: false });
		if (escrowCompletedRefetchResult.status == "success") {
			const requests = escrowCompletedRefetchResult.data.data.data.attributes;
			const sortedRequests = _.sortBy(requests, ["fulfilledAt"]).reverse();
			escrowCompletedDispatch({ type: ESCROW_COMPLETED_ACTIONS.updateState, key: "requests", value: sortedRequests });
		}
	};

	const [escrowCompletedState, escrowCompletedDispatch] = useReducer(EscrowCompletedReducer, {
		isFetchingEscrowCompleted: false,
		requests: [],
		fetchEscrowCompleted
	});



	

	//Get feaure flags
	useEffect(() => {
		(async () => {
			await fetchEscrowCompleted();
		})();
	}, []);

	return (
		<EscrowCompletedContext.Provider value={escrowCompletedState}>
			<EscrowCompletedDispatchContext.Provider value={escrowCompletedDispatch}>{children}</EscrowCompletedDispatchContext.Provider>
		</EscrowCompletedContext.Provider>
	);
};

export const useEscrowCompleted = () => {
	return useContext(EscrowCompletedContext);
};

export const useEscrowCompletedDispatch = () => {
	return useContext(EscrowCompletedDispatchContext);
};

const EscrowCompletedReducer = (escrowCompletedState, action) => {
	switch (action.type) {
		case ESCROW_COMPLETED_ACTIONS.updateState: {
			const newState = { ...escrowCompletedState };
			newState[action.key] = action.value;
			return newState;
		}
		case ESCROW_COMPLETED_ACTIONS.updateStates: {
			const newState = { ...escrowCompletedState };
			action.states.forEach(({ key, value }) => {
				newState[key] = value;
			});
			return newState;
		}
		default: {
			throw Error("[EscrowCompletedReducer] Unknown action: " + action.type);
		}
	}
};
