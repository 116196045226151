/* eslint-disable react/prop-types */
import { EscrowPendingProvider } from "../pending/escrowPendingProvider";
import { EscrowedRequestProvider } from "../request/escrowedRequestProvider";
import { EscrowCompletedProvider } from "../completed/escrowCompletedProvider";

const EscrowProviders = ({ children }) => {
  return (
    <EscrowCompletedProvider>
      <EscrowPendingProvider>
        <EscrowedRequestProvider>{children}</EscrowedRequestProvider>
      </EscrowPendingProvider>
    </EscrowCompletedProvider>
  );
};

export default EscrowProviders;
