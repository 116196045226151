import "./dashboard.scss";



const Dashboard = () => {
	

	
	return (
		<div className="dashboard_page">
			<div className="heading">
				<div className="title">Dashboard</div>
			</div>
		</div>
	);
};
export default Dashboard;
