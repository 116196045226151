import { Check, Calendar, Loader2, Pencil, X, Search } from "lucide-react";
import { useRates } from "../../../providers/Rates/RatesProvider";
import "react-datepicker/dist/react-datepicker.css";
import "./currencyprices.scss";
import DatePicker from "react-datepicker";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import Big from "big.js";
import { useAuth } from "../../../providers/AuthProvider";
import { useCurrencyFilters } from "./currencyFilterProvider";

const CurrencyPrices = () => {
  const {
    isLoadingRates,
    currencies,
    rates,
    getRate,
    lastUpdatedAt,
    saveRate,
    isSavingRate,
  } = useRates();
  const [editedRates, setEditedRates] = useState({});
  const { authTokens } = useAuth();
  const { updateFilters, filters } = useCurrencyFilters();

  //Set all editedRates saving to false when isSavingRate switches to false
  useEffect(() => {
    if (!isSavingRate) {
      const newEditedRates = {};
      Object.keys(editedRates).forEach((code) => {
        const editedRate = editedRates[code];
        editedRate.saving = false;
        newEditedRates[code] = editedRate;
      });
      setEditedRates(newEditedRates);
    }

  }, [isSavingRate]);

  const getPriceToUSD = (currency) => {
    //If there is an edited rate, get from edited rate, otherwise get from loaded rates
    let toUSDRate = "0";
    let fromUSDRate = "0";

    if (_.isEmpty(editedRates[currency.code])) {
      try {
        toUSDRate = getRate({
          rates,
          from: currency.code,
          to: "USD",
        });

        fromUSDRate = getRate({
          rates,
          from: "USD",
          to: currency.code,
        });
        // eslint-disable-next-line no-unused-vars
      } catch (err) {
        /* empty */
      }

      const editedRate = {
        editing: false,
        saving: false,
        fromUSDRate,
        toUSDRate,
      };
      setEditedRates({ ...editedRates, [currency.code]: editedRate });
    } else {
      const editedRate = editedRates[currency.code];
      toUSDRate = editedRate.toUSDRate;
      fromUSDRate = editedRate.fromUSDRate;
    }

    return (
      <>
        <div className="price_to_usd">
          <div>
            1 USD = {fromUSDRate} {currency.code}
          </div>
          <div>
            1 {currency.code} = {toUSDRate} USD
          </div>
        </div>
      </>
    );
  };

  const getLastUpdated = (currency) => {
    //If there is an edited rate with a timestamp, get from edited rate, otherwise get from loaded rates
    let timestamp;
    if (_.isEmpty(editedRates[currency.code]?.timestamp)) {
      timestamp = lastUpdatedAt[currency.code];
    } else {
      timestamp = editedRates[currency.code].timestamp;
    }

    if (_.isEmpty(timestamp)) return "--";
    return `${moment(timestamp).format("MMM D, YYYY [at] h:mm A")}`;
  };

  const editRate = (currency) => {
    const editedRate = editedRates[currency.code];
    editedRate.editing = true;
    setEditedRates({ ...editedRates, [currency.code]: editedRate });
  };

  const rateChanged = ({ toUSDRate, fromUSDRate, currency }) => {
    const editedRate = editedRates[currency.code];

    if (!_.isEmpty(toUSDRate)) {
      editedRate.toUSDRate = toUSDRate;
      if (toUSDRate == "0") {
        editedRate.fromUSDRate = "0";
      } else {
        editedRate.fromUSDRate = Big(1).div(Big(toUSDRate)).toString();
      }
    }

    if (!_.isEmpty(fromUSDRate)) {
      editedRate.fromUSDRate = fromUSDRate;
      if (fromUSDRate == "0") {
        editedRate.toUSDRate = "0";
      } else {
        editedRate.toUSDRate = Big(1).div(Big(fromUSDRate)).toString();
      }
    }

    if (_.isEmpty(toUSDRate) && _.isEmpty(fromUSDRate)) {
      editedRate.fromUSDRate = "";
      editedRate.toUSDRate = "";
    }

    setEditedRates({ ...editedRates, [currency.code]: editedRate });
  };

  const editingCurrency = (currency) => {
    return (
      !_.isEmpty(editedRates[currency.code]) &&
      editedRates[currency.code].editing
    );
  };

  const savingCurrency = (currency) => {
    return (
      !_.isEmpty(editedRates[currency.code]) &&
      editedRates[currency.code].saving
    );
  };

  const cancelEditRate = (currency) => {
    let toUSDRate = "0";
    let fromUSDRate = "0";

    try {
      toUSDRate = getRate({
        rates,
        from: currency.code,
        to: "USD",
      });

      fromUSDRate = getRate({
        rates,
        from: "USD",
        to: currency.code,
      });
      // eslint-disable-next-line no-unused-vars
    } catch (err) {
      /* empty */
    }

    const editedRate = {
      editing: false,
      saving: false,
      fromUSDRate,
      toUSDRate,
    };
    setEditedRates({ ...editedRates, [currency.code]: editedRate });
  };

  const updateRate = (currency) => {
    const editedRate = editedRates[currency.code];
    const token = authTokens.IdToken;
    const code = currency.code;
    const rate = editedRate.fromUSDRate;
    saveRate({ token, code, rate });
    editedRate.saving = true;
    editedRate.editing = false;
    setEditedRates({ ...editedRates, [currency.code]: editedRate });
  };

  const reactSelectStyleCurrencies = {
    control: (baseStyles) => ({
      ...baseStyles,
      fontSize: ".9rem",
      fontWeight: "600",
      padding: ".2rem",
      borderRadius: "8px",
      width: "100%",
      color: "#172c50",
      backgroundColor: "#ffffff",
      borderColor: "#3E9CF3",
    }),
  };

  const handleSearchChange = (e) => {
    updateFilters({ searchQuery: e.target.value });
  };
  const handleDateChange = (update) => {
    updateFilters({ lastUpdated: update });
  };

  const handleCurrencyChange = (selectedOption) => {
    updateFilters({ currency: selectedOption?.value || null });
  };




  const filteredCurrencies = currencies.filter((currency) => {
    const toUSDRate = getRate({
      rates,
      from: currency.code,
      to: "USD",
    })

    const fromUSDRate = getRate({
      rates,
      from: "USD",
      to: currency.code,
    })

    const matchesSearchQuery = filters.searchQuery
      ? currency.code.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
      currency.name.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
      toUSDRate.includes(filters.searchQuery) || // Check the toUSD rate
      fromUSDRate.includes(filters.searchQuery)   // Check the fromUSD rate
      : true;

    const matchesSelectedCurrency = filters.currency
      ? currency.code === filters.currency
      : true;

    const currencyLastUpdated = getLastUpdated(currency);
    const filterLastUpdated = filters.lastUpdated;

    // Convert currencyLastUpdated to a Date object
    const lastUpdatedDate = moment(currencyLastUpdated, "MMM D, YYYY [at] h:mm A").toDate();

    // Ensure filterLastUpdated is also a Date object
    const filterDate = filterLastUpdated ? new Date(filterLastUpdated) : null;

    // Compare only if filterDate is not null
    const matchesLastUpdated = filterDate
      ? lastUpdatedDate.setHours(0, 0, 0, 0) === filterDate.setHours(0, 0, 0, 0)
      : true;

    return matchesSearchQuery && matchesSelectedCurrency && matchesLastUpdated;
  });

  return (
    <div className="currency_prices_page">
      <div className="heading">
        <div className="title">Currency Prices</div>
      </div>
      <div className="search_inputs">
        <div className="search_input">
          <div className="label">Search</div>
          <div className="input search_query_container">
            <input
              className="search_query"
              type="text"
              placeholder="Search for currency, prices... etc"
              onChange={handleSearchChange}
            />
            <Search className="icon" />
          </div>
        </div>
        <div className="search_input">
          <div className="label">Currency</div>
          <div className="input currency">
            <ReactSelect
              className="currency_picker"
              styles={reactSelectStyleCurrencies}
              options={currencies.map((c) => ({ value: c.code, label: c.code }))}
              isClearable={true}
              onChange={handleCurrencyChange}
            />
          </div>
        </div>
        <div className="search_input">
          <div className="label">Date</div>
          <div className="input date_picker_container">
            <DatePicker
              popperProps={{ strategy: 'fixed' }}
              className="date_picker"
              onChange={handleDateChange}
              selected={filters.lastUpdated}
              isClearable={true}
              placeholderText="xx/xx/xxxx"
            />
            <Calendar className="icon" />
          </div>
        </div>
      </div>
      {(isLoadingRates || _.isEmpty(rates.rates)) && (
        <div className="currency_prices_loader_container">
          Loading currency prices...
          <Loader2 className="currency_prices_loader" />
        </div>
      )}

      {!isLoadingRates && !_.isEmpty(rates.rates) && (
        <>
          <table className="currency_prices_table">
            <thead>
              <tr>
                <th>Currency</th>
                <th>Price to USD</th>
                <th>Last Updated</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredCurrencies
                .filter((currency) => currency.code != "USD")
                .map((currency, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <div className="currency_info">
                          <div className="icon">
                            <img src={currency.logo} />
                          </div>
                          <div className="label">
                            <div className="symbol">{currency.code}</div>
                            <div className="name">{currency.name}</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        {/* If we are not editing a currency */}
                        {!editingCurrency(currency) && getPriceToUSD(currency)}

                        {/* If we are editing a currency */}
                        {editingCurrency(currency) && (
                          <>
                            <div className="editing_price_to_usd">
                              <div className="editing_price_to_usd_input">
                                1 USD ={" "}
                                <input
                                  type="number"
                                  value={editedRates[currency.code].fromUSDRate}
                                  onChange={(e) =>
                                    rateChanged({
                                      currency,
                                      fromUSDRate: e.target.value,
                                    })
                                  }
                                />{" "}
                                {currency.code}
                              </div>
                              <div className="editing_price_to_usd_input">
                                1 {currency.code} ={" "}
                                <input
                                  type="number"
                                  value={editedRates[currency.code].toUSDRate}
                                  onChange={(e) =>
                                    rateChanged({
                                      currency,
                                      toUSDRate: e.target.value,
                                    })
                                  }
                                />{" "}
                                USD
                              </div>
                            </div>
                          </>
                        )}
                      </td>
                      <td>{getLastUpdated(currency)}</td>
                      <td>
                        <div className="currency_price_controls">
                          {/* If we are not editing a currency and not saving*/}
                          {!editingCurrency(currency) &&
                            !savingCurrency(currency) && (
                              <Pencil
                                className="edit currency_price_control"
                                onClick={() => editRate(currency)}
                              />
                            )}

                          {/* If we are editing a currency */}
                          {editingCurrency(currency) && !isSavingRate && (
                            <>
                              <Check
                                className="save_edit currency_price_control"
                                onClick={() => updateRate(currency)}
                              />
                              <X
                                className="cancel_edit currency_price_control"
                                onClick={() => cancelEditRate(currency)}
                              />
                            </>
                          )}

                          {/* If we are saving a currency */}
                          {savingCurrency(currency) && (
                            <Loader2 className="saving_rate_loader currency_price_control" />
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
export default CurrencyPrices;
