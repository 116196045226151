import axios from "axios";

const getApiDomain = () => {
  const origin = location.origin;
  switch (origin) {
    case "http://localhost:5174":
      return "https://admin-dev-api.muda-v2.com";
    case "https://admin-staging.muda-v2.com":
      return "https://admin-staging-api.muda-v2.com";
    case "https://admin.muda-v2.com":
      return "https://admin-api.muda-v2.com";
    default:
      return "https://admin-dev-api.muda-v2.com";
      //return "http://127.0.0.1:8787";
  }
};

const login = async (creds) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/signin`, creds);
};

const verifyToken = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/verify/token`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getRates = async () => {
  const domain = getApiDomain();
  return axios.get(`${domain}/rates`);
};

const updateRate = async ({ token, code, rate }) => {
  const domain = getApiDomain();
  const envelope = {
    currencyCode: code,
    newRate: rate
  }
  return axios.post(`${domain}/rates`, envelope, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getPairPrices = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/pairprices`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const updatePairPrice = async ({ token, pair, markup, markdown, enabled }) => {
  const domain = getApiDomain();
  const envelope = {
    currencyPair: pair,
    markup,
    markdown,
    enabled
  }
  return axios.post(`${domain}/pairprices`, envelope, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getEscrowPending = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/escrow/pending`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getEscrowCompleted = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/escrow/completed`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getEscrowedRequest = async ({ token, id }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/escrow/request/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const rejectProofOfPayment = async ({ token, order, request, pop }) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/orders/${order.id}/requests/${request.id}/proofofpayments/${pop.id}/reject`, {
    id: pop.id
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getPendingTransactions = async ({ token }) => {
  const domain = getApiDomain();
  return axios.get(`${domain}/pendingTransactions`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const verifyProofOfPayment = async ({ token, order, request, pop }) => {
  const domain = getApiDomain();
  return axios.post(`${domain}/orders/${order.id}/requests/${request.id}/proofofpayments/${pop.id}/verify`, {
    id: pop.id
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const updateTransaction = async ({ token, transactionId,status }) => {
  const domain = getApiDomain();
  const envelope = {
    transactionId, 
    status
  };
  return axios.post(`${domain}/approveTransaction`, envelope, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const api = {
  getApiDomain,
  login,
  verifyToken,
  getRates,
  updateRate,
  getPairPrices,
  updatePairPrice,
  getEscrowPending,
  getEscrowedRequest,
  rejectProofOfPayment,
  verifyProofOfPayment,
  getPendingTransactions,
  updateTransaction,
  getEscrowCompleted
};

export default api;
