import "./limits.scss";

const Limits = () => {
	return (
		<div className="limits_page">
			<div className="heading">
				<div className="title">Limits</div>
			</div>
		</div>
	);
};
export default Limits;
