import "./reports.scss";



const Reports = () => {
	
	return (
		<div className="reports_page">
			<div className="heading">
				<div className="title">Reports</div>
			</div>
		</div>
	);
};
export default Reports;
