import cn from "classnames";
import { Loader2, RefreshCw, Search, Calendar } from "lucide-react";
import "./escrowPending.scss";
import { useEscrowPending } from "./escrowPendingProvider";
import { formatAmount } from "../../../common/formatAmount";
import moment from "moment";
import { useState } from "react";
import _ from "lodash";
import { useRates } from "../../../providers/Rates/RatesProvider";
import Big from "big.js";
import { ESCROWED_REQUEST_ACTIONS, useEscrowedRequestDispatch } from "../request/escrowedRequestProvider";
import { useNavigate } from "react-router-dom";
import { useEscrowFilters } from "../escrowFiltersProvider";
import ReactSelect from "react-select";
import ReactDatePicker from "react-datepicker";

const reactSelectStyleEscrowPending = {
	control: (baseStyles) => ({
	  ...baseStyles,
	  fontSize: ".9rem",
	  fontWeight: "600",
	  padding: ".2rem",
	  borderRadius: "8px",
	  width: "100%",
	  color: "#172c50",
	  backgroundColor: "#ffffff",
	  borderColor: "#3E9CF3",
	}),
  };

const EscrowPending = () => {
	const navigate = useNavigate();
	 const { currencies } = useRates();
	const { isFetchingEscrowPending, requests, fetchEscrowPending } = useEscrowPending();
	const { updateFilters, filters } = useEscrowFilters();
	const escrowedRequestDispatch = useEscrowedRequestDispatch();
	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;


	const handleSearchChange = (e) => {
		updateFilters({ searchQuery: e.target.value });
	  };
	
	  const handleDateChange = (update) => {
		setDateRange(update);
		updateFilters({ startDate: update[0], endDate: update[1] });
	  };
	
	  const handleBuyCurrencyChange = (selectedOption) => {
		updateFilters({ buyCurrency: selectedOption?.value || null });
	  };
	
	  const handleSellCurrencyChange = (selectedOption) => {
		updateFilters({ sellCurrency: selectedOption?.value || null });
	  };

	  //filtering 
	
	
	const applyFilters = (requests) => {
		let filteredRequests = requests;
	
		// Search filter
		if (filters.searchQuery) {
			filteredRequests = filteredRequests.filter(
				(request) =>
					request.id.toLowerCase().includes(filters.searchQuery.toLowerCase()) || 
					request.order.sellCurrency.toLowerCase().includes(filters.searchQuery.toLowerCase()) || 
					request.order.buyCurrency.toLowerCase().includes(filters.searchQuery.toLowerCase()) || 
					request.order.createdByName.toLowerCase().includes(filters.searchQuery.toLowerCase()) 
			);
		}
	
		// Date filter
		if (filters.startDate && filters.endDate) {
			const start = new Date(filters.startDate); // Ensure this is in a valid format like "2024-12-01T00:00:00Z"
			const end = new Date(filters.endDate); // Ensure this is valid too
		
			if (!isNaN(start) && !isNaN(end)) {
				filteredRequests = filteredRequests.filter((request) => {
					const requestDate = new Date(request.createdAt); // Parse the ISO date
					return requestDate >= start && requestDate <= end;
				});
			} else {
				console.error("Invalid start or end date:", { start, end });
			}
		}
	
		// Define filter mode based on buyCurrency and sellCurrency
		const buyCurrencyFilter = filters.buyCurrency ? [filters.buyCurrency] : [];
		const sellCurrencyFilter = filters.sellCurrency ? [filters.sellCurrency] : [];
	
		let filterMode = "NONE";
		if (buyCurrencyFilter.length > 0 && sellCurrencyFilter.length > 0) {
			filterMode = "BOTH";
		} else if (buyCurrencyFilter.length > 0) {
			filterMode = "BUY";
		} else if (sellCurrencyFilter.length > 0) {
			filterMode = "SELL";
		}
	
		filteredRequests = filteredRequests.filter((request) => {
			let showRequest = true;
			const { buyCurrency, sellCurrency } = request.order;
	
			switch (filterMode) {
				case "BUY":
					showRequest = buyCurrencyFilter.includes(buyCurrency);
					break;
				case "SELL":
					showRequest = sellCurrencyFilter.includes(sellCurrency);
					break;
				case "BOTH":
					showRequest = buyCurrencyFilter.includes(buyCurrency) && sellCurrencyFilter.includes(sellCurrency);
					break;
				case "NONE":
				default:
					showRequest = true;
					break;
			}
	
			return showRequest;
		});
	
		return filteredRequests;
	};
	
	const filteredRequests = applyFilters(requests);
	

	 

	const loadingPage = () => {
		return isFetchingEscrowPending;
	};

	// const reloadPage = async () => {
	// 	//refresh escrow pending
	// };

	const viewRequest = ({ request }) => {
		escrowedRequestDispatch({
			type: ESCROWED_REQUEST_ACTIONS.updateStates,
			states: [
				{ key: "back", value: "/escrow/pending" },
				{ key: "activeRequest", value: request },
				{ key: "activeRequestId", value: request.id },
			],
		});
		navigate(`/escrow/pending/request/${request.id}`);
	};

	const getExchangeRate = ({ request }) => {
		const sellCurrency = request.order.sellCurrency;
		const buyCurrency = request.order.buyCurrency;
		const buyRate = formatAmount(request.order.volumeTypeAmount);
		const sellRate = formatAmount(Big(1).div(Big(request.order.volumeTypeAmount)).toString());
		return (
			<>
				<p>
					1 {sellCurrency} = {buyRate} {buyCurrency}
				</p>
				<p>
					1 {buyCurrency} = {sellRate} {sellCurrency}
				</p>
			</>
		);
	};

	const getSelling = ({ request }) => {
		const currency = request.order.sellCurrency;
		const rate = request.order.volumeTypeAmount;
		let amount;
		if (!_.isEmpty(`${request.desiredPurchaseAmount}`) && !_.isEmpty(rate)) {
			amount = formatAmount(Big(request.desiredPurchaseAmount).div(Big(rate)).toString());
		}
		const selling = `${amount} ${currency}`;
		return <p>{selling}</p>;
	};

	const getBuying = ({ request }) => {
		const currency = request.order.buyCurrency;
		const amount = formatAmount(request.desiredPurchaseAmount);
		const buying = `${amount} ${currency}`;
		return <p>{buying}</p>;
	};



	return (
		<div id="escrow_pending_page">
			<div className="heading">
				<div className="title">Pending Escrow Orders</div>
				<RefreshCw onClick={fetchEscrowPending} className={cn("refresh_icon", { rotating: loadingPage() })} />
			</div>
			<div className="search_inputs">
			<div className="search_input currency_picker_container">
		<div className="label">Currency</div>
		<div className="input currency">
		  <ReactSelect
			className="currency_picker"
			styles={reactSelectStyleEscrowPending}
			options={currencies.map((c) => ({ value: c.code, label: c.code }))}
			isClearable={true}
				  onChange={handleBuyCurrencyChange}
		  />
		  <ReactSelect
			className="currency_picker"
			styles={reactSelectStyleEscrowPending}
			options={currencies.map((c) => ({ value: c.code, label: c.code }))}
				  isClearable={true}
			onChange={handleSellCurrencyChange}
		  />
		</div>
	  </div>
	  <div className="search_input">
		<div className="label">Search</div>
		<div className="input search_query_container">
		  <input
			className="search_query"
			type="text"
			placeholder="Search for currency, user... etc"
			onChange={handleSearchChange}
		  />
		  <Search className="icon" />
		</div>
	  </div>
	  <div className="search_input">
		<div className="label">Date</div>
		<div className="input date_picker_container">
		  <ReactDatePicker
			className="date_picker"
			selectsRange={true}
			startDate={startDate}
			endDate={endDate}
			onChange={handleDateChange}
			isClearable={true}
			placeholderText="xx/xx/xxxx - xx/xx/xxxx"
		  />
		  <Calendar className="icon" />
		</div>
	  </div>
	  
	</div>

			{loadingPage() && (
				<div className="escrow_pending_loader_container">
					Loading pending orders...
					<Loader2 className="escrow_pending_loader" />
				</div>
			)}

			{!loadingPage() && (
				<table className="escrow_pending_table transactions_table">
					<thead>
						<tr>
							<th>
								Pair <span className="help_text">( Buy - Sell )</span>
							</th>
							<th>Price</th>
							<th>Selling</th>
							<th>Buying</th>
							<th>Seller</th>
							<th>Buyer</th>
							<th>Date</th>
						</tr>
					</thead>
					<tbody>
						{filteredRequests.map((request) => (
							<tr key={request.id} onClick={() => viewRequest({ request })}>
								<td>
									{request.order.buyCurrency} - {request.order.sellCurrency}
								</td>
								<td>{getExchangeRate({ request })}</td>
								<td>{getSelling({ request })}</td>
								<td>{getBuying({ request })}</td>
								<td>{request.order.createdByName}</td>
								<td>{request.createdByName}</td>
								<td>{moment(request.createdAt).format("DD/MM/YYYY - h:mm A")}</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};
export default EscrowPending;
