import { useRef } from "react";
import cn from "classnames";
import logo from "../assets/logo.svg";
import profileImage from "../assets/profile-pic.png";
import "./header.scss";
import Version from "../version/version";
import { useAuth } from "../providers/AuthProvider";
import ProfilePopOver from "./profilePopover/profilePopover";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const profileClick = useRef(null);
  const profilePopover = useRef(null);

  const positionProfilePopover = () => {
    const dp = profileClick.current;
    const window = profilePopover.current;

    window.style.top = `${dp.offsetTop + dp.offsetHeight + 5}px`;
    window.style.left = `${dp.offsetLeft - (300 / 2 - dp.offsetWidth / 2)}px`;
  };

  const gotoLogin = () => {
    navigate(`/login`);
  };

  return (
    <div id="header_component">
      <div className={cn("header", { is_logged_in: isLoggedIn })}>
        {!isLoggedIn && (
          <div className="logo">
            <img src={logo} alt="" />
            <div className="header_admin_tag">ADMIN</div>
          </div>
        )}

        <div className="rates_container">
          {isLoggedIn && (
            <>
              <button
                className="profile"
                ref={profileClick}
                onClick={positionProfilePopover}
                id="profile_click_control"
                popovertarget="profile_avatar_popover"
                popovertargetaction="show"
              >
                <img src={profileImage} alt="" />
              </button>
              <div
                ref={profilePopover}
                id="profile_avatar_popover"
                popover="auto"
              >
                <ProfilePopOver />
              </div>
            </>
          )}
        </div>

        {!isLoggedIn && location.pathname != "/login" && (
          <div className="cta" onClick={gotoLogin}>
            Log in
          </div>
        )}

        {/* <Popover className="relative">
          <Popover.Button>Solutions</Popover.Button>

          <Popover.Panel className="absolute z-10">
            <div className="grid grid-cols-2">
              <a href="/analytics">Analytics</a>
              <a href="/engagement">Engagement</a>
              <a href="/security">Security</a>
              <a href="/integrations">Integrations</a>
            </div>

            <img
              src="/solutions.jpg"
              alt=""
            />
          </Popover.Panel>
        </Popover> */}
      </div>
      <Version />
    </div>
  );
};

export default Header;
