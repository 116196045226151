/* eslint-disable react/prop-types */
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./AuthProvider";
import { CurrencyFiltersProvider } from "../workspace/buyandsell/currencyprices/currencyFilterProvider";
import { FeatureFlagsProvider } from "./FeatureFlags/FeatureFlagsProvider";
import { RatesProvider } from "./Rates/RatesProvider";
import { EscrowFiltersProvider } from "../workspace/escrow/escrowFiltersProvider";
import EscrowProviders from "../workspace/escrow/providers/escrowProviders";

const queryClient = new QueryClient();

const SystemProviders = ({ children }) => {
	return (
		<QueryClientProvider client={queryClient}>
			<AuthProvider>
				<FeatureFlagsProvider>
					<RatesProvider>
						<CurrencyFiltersProvider>
							<EscrowFiltersProvider>
							<EscrowProviders>{children}</EscrowProviders>
							</EscrowFiltersProvider>
						</CurrencyFiltersProvider>
					</RatesProvider>
				</FeatureFlagsProvider>
			</AuthProvider>
		</QueryClientProvider>
	);
};

export default SystemProviders;
