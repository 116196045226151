/* eslint-disable no-unused-vars */
export const reactSelectStyle = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        fontSize: ".9rem",
        fontWeight: "600",
        padding: ".2rem",
        borderRadius: "8px",
        color: "#172c50",
        backgroundColor: "#e4efff",
        borderColor: "light-dark(rgb(118, 118, 118), rgb(133, 133, 133))",
    }),
};

export const reactSelectStyleWhite = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        fontSize: ".9rem",
        fontWeight: "600",
        padding: ".2rem",
        borderRadius: "8px",
        color: "#172c50",
        backgroundColor: "#ffffff",
        borderColor: "#3E9CF3",
    }),
};