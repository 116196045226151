import { useRef } from "react";
import cn from "classnames";
import Login from "../login/login";
import { useAuth } from "../providers/AuthProvider";
import logo from "../assets/logo.svg";

import dashboard_icon from "../assets/dashboard_icon.svg";
import escrow_icon from "../assets/escrow_icon.svg";
// import reports_icon from "../assets/reports_icon.svg";
import transaction from "../assets/transaction.svg";
import users_icon from "../assets/users_icon.svg";
// import people_icon from "../assets/people.svg";
import logout_icon from "../assets/logout_icon.svg";
import buy_and_sell_icon from "../assets/buy_and_sell_icon.svg";
import Header from "../header/header";
import "./workspace.scss";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";

const Workspace = () => {
	const { isLoggedIn, setIsLoggedIn, setAuthTokens } = useAuth();

	const logoutDialogRef = useRef(null);
	const navigate = useNavigate();
	const location = useLocation();

	const links = [
		{
			icon: dashboard_icon,
			name: "Dashboard",
			url: "/",
		},
		// {
		// 	name: "Settings",
		// 	class: "settings_link",
		// 	url: "/settings",
		// },
		// {
		// 	icon: reports_icon,
		// 	name: "Reports",
		// 	url: "/reports",
		// },
		{
			icon: escrow_icon,
			name: "Escrow",
			url: "/escrow",
			links: [
				{
					name: "Pending",
					url: "/escrow/pending",
				},
				{
					name: "Completed",
					url: "/escrow/completed",
				},
			],
		},
		{
			icon: buy_and_sell_icon,
			name: "Buy & Sell",
			url: "/buyandsell",
			links: [
				{
					name: "Currency Prices",
					url: "/buyandsell/currencyprices",
				},
				{
					name: "Pair Prices",
					url: "/buyandsell/pairprices",
				},
				{
					name: "Fees",
					url: "/buyandsell/fees",
				},
				{
					name: "Limits",
					url: "/buyandsell/limits",
				},
			],
		},
		{
			icon: users_icon,
			name: "Users",
			url: "/users/clients",
			links: [
				{
					name: "Clients",
					url: "/users/clients",
				},
				{
					name: "Admins",
					url: "users/admin",
				},
			],
		},
		{
			icon: transaction,
			name: "Transactions",
			url: "/transactions",
			links: [
				{
					name: "Pending",
					url: "/transactions/pending",
				},
				{
					name: "Completed",
					url: "/transactions/completed",
				},
			],
		},
	];

	const startLogout = () => {
		logoutDialogRef.current.showModal();
	};

	const cancelLogout = () => {
		logoutDialogRef.current.close();
	};

	const confirmLogout = () => {
		//Logout (should be in auth provider)
		localStorage.removeItem("authTokens");
		setAuthTokens({});
		setIsLoggedIn(false);
		navigate("/login");
	};

	const linkActive = (link) => {
		if (link.name == "Dashboard") return location.pathname == link.url;
		return location.pathname.includes(link.url);
	};

	return (
		<>
			{!isLoggedIn && <Login />}
			{isLoggedIn && (
				<div className="workspace">
					<div className="sidebar">
						<div className="logo">
							<img src={logo} alt="" />
							<div className="sidebar_admin_tag">ADMIN</div>
						</div>
						<div className="links">
							{links.map((link) => {
								let linkHtml;

								// If there are no sublinks
								if (_.isEmpty(link.links)) {
									linkHtml = (
										<Link className={cn("link", link.class, { active: linkActive(link) })} key={link.url} to={link.url}>
											<div className="highlighter">
												<img src={link.icon} alt="" className="icon" />
												<span className="label">{link.name}</span>
											</div>
										</Link>
									);
								}

								//If there are sublinks
								if (!_.isEmpty(link.links)) {
									linkHtml = (
										<div className="link_list" key={link.url}>
											<div className={cn("link", link.class, { active: linkActive(link) })} key={link.url}>
												<div className="highlighter">
													<img src={link.icon} alt="" className="icon" />
													<span className="label">{link.name}</span>
												</div>
											</div>
											<div className="sub_links">
												{link.links.map((sublink) => (
													<Link className={cn("link", sublink.class, { active: linkActive(sublink) })} key={sublink.url} to={sublink.url}>
														<div className="highlighter">
															<img src={sublink.icon} alt="" className="icon" />
															<span className="label">{sublink.name}</span>
														</div>
													</Link>
												))}
											</div>
										</div>
									);
								}

								//Only check feature flags if link has featureName
								if (link.featureName) {
									if (link.featureName) {
										return linkHtml;
									} else {
										//This is just a placeholder for hidden content
										return <a key={link.url} style={{ display: "none" }}></a>;
									}
								} else {
									return linkHtml;
								}
							})}
						</div>
						<div className="logout_container" onClick={startLogout}>
							<div className="logout_icon">
								<img src={logout_icon} alt="" />
							</div>
							<div className="logout_label">Logout</div>
						</div>
						<dialog className="logout_dialog_container" ref={logoutDialogRef}>
							<div className="logout_dialog_heading">Are you sure you want to log out?</div>
							<div className="logout_dialog_controls">
								<div onClick={confirmLogout} className="logout_dialog_control logout_dialog_confirm">
									Yes
								</div>
								<div onClick={cancelLogout} className="logout_dialog_control logout_dialog_cancel">
									No
								</div>
							</div>
						</dialog>
					</div>
					<div className="main_content">
						<Header />
						<Outlet />
					</div>
				</div>
			)}
		</>
	);
};

export default Workspace;
