import "./users.scss";
import { Pencil, Check, X, Loader2, Search, FileText, PlusCircle } from "lucide-react";
import { useState, useEffect } from "react";

const AdminUsers = () => {
    const [users, setUsers] = useState([
        { id: 1, name: "John Doe", email: "john@example.com", role: "Admin", registrationDate: "2023-01-01", rights: "Not Approver" },
        { id: 2, name: "Jane Smith", email: "jane@example.com", role: "User", registrationDate: "2023-02-01", rights: "Not Approver" },
        { id: 3, name: "Alice Johnson", email: "alice@example.com", role: "User", registrationDate: "2023-03-01", rights: "Not Approver" },
        { id: 4, name: "Bob Brown", email: "bob@example.com", role: "Admin", registrationDate: "2023-04-01", rights: "Not Approver" },
        { id: 5, name: "Charlie Davis", email: "charlie@example.com", role: "User", registrationDate: "2023-05-01", rights: "Not Approver" },
        { id: 6, name: "Diana Evans", email: "diana@example.com", role: "User", registrationDate: "2023-06-01", rights: "Not Approver" },
        { id: 7, name: "Eve Foster", email: "eve@example.com", role: "Admin", registrationDate: "2023-07-01", rights: "Not Approver" },
        { id: 8, name: "Frank Green", email: "frank@example.com", role: "User", registrationDate: "2023-08-01", rights: "Not Approver" },
        { id: 9, name: "Grace Harris", email: "grace@example.com", role: "User", registrationDate: "2023-09-01", rights: "Not Approver" },
        { id: 10, name: "Hank Irving", email: "hank@example.com", role: "Admin", registrationDate: "2023-10-01", rights: "Not Approver" },
        { id: 11, name: "Ivy Johnson", email: "ivy@example.com", role: "User", registrationDate: "2023-11-01", rights: "Not Approver" },
        { id: 12, name: "Jack King", email: "jack@example.com", role: "User", registrationDate: "2023-12-01", rights: "Not Approver" },
        { id: 13, name: "Karen Lee", email: "karen@example.com", role: "Admin", registrationDate: "2024-01-01", rights: "Not Approver" },
        { id: 14, name: "Leo Martin", email: "leo@example.com", role: "User", registrationDate: "2024-02-01", rights: "Not Approver" },
        { id: 15, name: "Mia Nelson", email: "mia@example.com", role: "User", registrationDate: "2024-03-01", rights: "Not Approver" },
        { id: 16, name: "Nina Owens", email: "nina@example.com", role: "Admin", registrationDate: "2024-04-01", rights: "Not Approver" },
        { id: 17, name: "Oscar Perez", email: "oscar@example.com", role: "User", registrationDate: "2024-05-01", rights: "Not Approver" },
        { id: 18, name: "Paul Quinn", email: "paul@example.com", role: "User", registrationDate: "2024-06-01", rights: "Not Approver" },
        { id: 19, name: "Quincy Roberts", email: "quincy@example.com", role: "Admin", registrationDate: "2024-07-01", rights: "Not Approver" },
        { id: 20, name: "Rachel Smith", email: "rachel@example.com", role: "User", registrationDate: "2024-08-01", rights: "Not Approver" },
        { id: 21, name: "Sam Taylor", email: "sam@example.com", role: "User", registrationDate: "2024-09-01", rights: "Not Approver" },
        { id: 22, name: "Tina Underwood", email: "tina@example.com", role: "Admin", registrationDate: "2024-10-01", rights: "Not Approver" },
        { id: 23, name: "Uma Vance", email: "uma@example.com", role: "User", registrationDate: "2024-11-01", rights: "Not Approver" },
        { id: 24, name: "Victor White", email: "victor@example.com", role: "User", registrationDate: "2024-12-01", rights: "Not Approver" },
        { id: 25, name: "Wendy Xander", email: "wendy@example.com", role: "Admin", registrationDate: "2025-01-01", rights: "Not Approver" },
    ]);
    const [editedUsers, setEditedUsers] = useState({});
    const [isSavingUser, setIsSavingUser] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [showKycModal, setShowKycModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [isApproving, setIsApproving] = useState(false);

    useEffect(() => {
        const newEditedUsers = {};
        users.forEach((user) => {
            newEditedUsers[user.id] = { ...user, editing: false, saving: false };
        });
        setEditedUsers(newEditedUsers);
    }, [users]);

    const editUser = (user) => {
        const editedUser = editedUsers[user.id];
        editedUser.editing = true;
        setEditedUsers({
            ...editedUsers,
            [user.id]: editedUser,
        });
    };

    const editingUser = (user) => {
        return editedUsers[user.id] && editedUsers[user.id].editing;
    };

    const savingUser = (user) => {
        return editedUsers[user.id] && editedUsers[user.id].saving;
    };

    const cancelEditUser = (user) => {
        const oldUser = users.find((u) => u.id === user.id);
        setEditedUsers({
            ...editedUsers,
            [user.id]: { ...oldUser, editing: false, saving: false },
        });
    };

    const updateUser = (user) => {
        const editedUser = editedUsers[user.id];
        setIsSavingUser(true);
        // Simulate saving user
        setTimeout(() => {
            setUsers(users.map((u) => (u.id === user.id ? editedUser : u)));
            setIsSavingUser(false);
            setEditedUsers({
                ...editedUsers,
                [user.id]: { ...editedUser, editing: false, saving: false },
            });
        }, 1000);
    };

    const handleInputChange = (user, field, value) => {
        const editedUser = editedUsers[user.id];
        editedUser[field] = value;
        setEditedUsers({
            ...editedUsers,
            [user.id]: editedUser,
        });
    };

    const addUser = () => {
        const newUser = {
            id: users.length + 1,
            name: "",
            email: "",
            role: "",
            registrationDate: new Date().toISOString().split("T")[0],
            rights: "Not Approver",
            editing: true,
            saving: false,
        };
        setUsers([...users, newUser]);
        setEditedUsers({
            ...editedUsers,
            [newUser.id]: newUser,
        });
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    const filteredUsers = users.filter((user) => {
        return (
            user.name.toLowerCase().includes(searchQuery) ||
            user.email.toLowerCase().includes(searchQuery) ||
            user.role.toLowerCase().includes(searchQuery) ||
            user.registrationDate.includes(searchQuery)
        );
    });

    const openKycModal = (user) => {
        setSelectedUser(user);
        setShowKycModal(true);
    };

    const closeKycModal = () => {
        setShowKycModal(false);
        setSelectedUser(null);
    };

    const handleKycUpload = (e) => {
        // Handle KYC document upload
        console.log("KYC document uploaded for user:", selectedUser);
        closeKycModal();
    };

    const makeUserApprover = (user) => {
        setSelectedUser(user);
        setShowConfirmDialog(true);
    };

    const confirmMakeUserApprover = () => {
        setIsApproving(true);
        setTimeout(() => {
            const updatedUser = { ...selectedUser, rights: "Approver" };
            setUsers(users.map((u) => (u.id === selectedUser.id ? updatedUser : u)));
            setShowConfirmDialog(false);
            setSelectedUser(null);
            setIsApproving(false);
        }, 3000);
    };

    const cancelMakeUserApprover = () => {
        setShowConfirmDialog(false);
        setSelectedUser(null);
    };

    return (
        <div className="client_users_page">
            <div className="heading">
                <div className="title">Admin Users</div>
            </div>
            <div className="search_inputs">
                <div className="search_input">
                    <div className="input search_query_container">
                        <input
                            className="search_query"
                            type="text"
                            placeholder="Search for users..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                        <Search className="icon" />
                    </div>
                </div>
                <button onClick={addUser} className="add_user_button">
                    Add Admin
                </button>
            </div>
            <div className="users_table_container">
                <table className="users_table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Registration Date</th>
                            <th>Rights</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredUsers.map((user) => (
                            <tr key={user.id}>
                                <td>{user.id}</td>
                                <td>
                                    {!editingUser(user) ? (
                                        user.name
                                    ) : (
                                        <input
                                            type="text"
                                            className="edit_input"
                                            value={editedUsers[user.id].name}
                                            onChange={(e) =>
                                                handleInputChange(user, "name", e.target.value)
                                            }
                                        />
                                    )}
                                </td>
                                <td>
                                    {!editingUser(user) ? (
                                        user.email
                                    ) : (
                                        <input
                                            type="email"
                                            className="edit_input"
                                            value={editedUsers[user.id].email}
                                            onChange={(e) =>
                                                handleInputChange(user, "email", e.target.value)
                                            }
                                        />
                                    )}
                                </td>
                                <td>
                                    {!editingUser(user) ? (
                                        user.role
                                    ) : (
                                        <input
                                            type="text"
                                            className="edit_input"
                                            value={editedUsers[user.id].role}
                                            onChange={(e) =>
                                                handleInputChange(user, "role", e.target.value)
                                            }
                                        />
                                    )}
                                </td>
                                <td>
                                    {!editingUser(user) ? (
                                        user.registrationDate
                                    ) : (
                                        <input
                                            type="date"
                                            className="edit_input"
                                            value={editedUsers[user.id].registrationDate}
                                            onChange={(e) =>
                                                handleInputChange(user, "registrationDate", e.target.value)
                                            }
                                        />
                                    )}
                                </td>
                                <td>{user.rights}</td>
                                <td>
                                    <div className="user_controls">
                                        <FileText
                                            className="kyc user_control"
                                            onClick={() => openKycModal(user)}
                                        />
                                        {user.rights === "Not Approver" && (
                                            <button
                                                className="approval_rights_button"
                                                onClick={() => makeUserApprover(user)}
                                                disabled={isApproving}
                                            >
                                                {isApproving ? (
                                                    <Loader2 className="icon" />
                                                ) : (
                                                    <>
                                                        <PlusCircle className="icon" /> Approval rights
                                                    </>
                                                )}
                                            </button>
                                        )}
                                        {!editingUser(user) && !savingUser(user) && (
                                            <Pencil
                                                className="edit user_control"
                                                onClick={() => editUser(user)}
                                            />
                                        )}
                                        {editingUser(user) && !savingUser(user) && (
                                            <>
                                                <Check
                                                    className="save_edit user_control"
                                                    onClick={() => updateUser(user)}
                                                />
                                                <X
                                                    className="cancel_edit user_control"
                                                    onClick={() => cancelEditUser(user)}
                                                />
                                            </>
                                        )}
                                        {savingUser(user) && (
                                            <Loader2 className="saving_user_loader user_control" />
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showConfirmDialog && (
                <div className="confirm_dialog">
                    <div className="confirm_dialog_content">
                        <h2>Confirm Action</h2>
                        <p>Are you sure you want to make {selectedUser.name} an approver?</p>
                        <div className="modal_buttons">
                            <button onClick={confirmMakeUserApprover} disabled={isApproving}>
                                {isApproving ? <Loader2 className="icon" /> : "Confirm"}
                            </button>
                            <button onClick={cancelMakeUserApprover} disabled={isApproving}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showKycModal && (
                <div className="kyc_modal">
                    <div className="kyc_modal_content">
                        <h2>Upload KYC Documents for {selectedUser.name}</h2>
                        <input type="file" onChange={handleKycUpload} />
                        <button onClick={closeKycModal}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminUsers;