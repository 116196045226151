/* eslint-disable react/jsx-no-undef */
import "./users.scss";
import { Pencil, Check, X, Loader2, Search, FileText, Info } from "lucide-react";
import { useState, useEffect } from "react";
import _ from "lodash";
import { useForm } from "react-hook-form";
import cn from "classnames";

const ClientUsers = () => {
  const [users, setUsers] = useState([
    { id: 1, name: "John Doe", email: "john@example.com", role: "Admin", registrationDate: "2023-01-01" },
    { id: 2, name: "Jane Smith", email: "jane@example.com", role: "User", registrationDate: "2023-02-01" },
    { id: 3, name: "Alice Johnson", email: "alice@example.com", role: "User", registrationDate: "2023-03-01" },
    { id: 4, name: "Bob Brown", email: "bob@example.com", role: "Admin", registrationDate: "2023-04-01" },
    { id: 5, name: "Charlie Davis", email: "charlie@example.com", role: "User", registrationDate: "2023-05-01" },
    { id: 6, name: "Diana Evans", email: "diana@example.com", role: "User", registrationDate: "2023-06-01" },
    { id: 7, name: "Eve Foster", email: "eve@example.com", role: "Admin", registrationDate: "2023-07-01" },
    { id: 8, name: "Frank Green", email: "frank@example.com", role: "User", registrationDate: "2023-08-01" },
    { id: 9, name: "Grace Harris", email: "grace@example.com", role: "User", registrationDate: "2023-09-01" },
    { id: 10, name: "Hank Irving", email: "hank@example.com", role: "Admin", registrationDate: "2023-10-01" },
    { id: 11, name: "Ivy Johnson", email: "ivy@example.com", role: "User", registrationDate: "2023-11-01" },
    { id: 12, name: "Jack King", email: "jack@example.com", role: "User", registrationDate: "2023-12-01" },
    { id: 13, name: "Karen Lee", email: "karen@example.com", role: "Admin", registrationDate: "2024-01-01" },
    { id: 14, name: "Leo Martin", email: "leo@example.com", role: "User", registrationDate: "2024-02-01" },
    { id: 15, name: "Mia Nelson", email: "mia@example.com", role: "User", registrationDate: "2024-03-01" },
    { id: 16, name: "Nina Owens", email: "nina@example.com", role: "Admin", registrationDate: "2024-04-01" },
    { id: 17, name: "Oscar Perez", email: "oscar@example.com", role: "User", registrationDate: "2024-05-01" },
    { id: 18, name: "Paul Quinn", email: "paul@example.com", role: "User", registrationDate: "2024-06-01" },
    { id: 19, name: "Quincy Roberts", email: "quincy@example.com", role: "Admin", registrationDate: "2024-07-01" },
    { id: 20, name: "Rachel Smith", email: "rachel@example.com", role: "User", registrationDate: "2024-08-01" },
    { id: 21, name: "Sam Taylor", email: "sam@example.com", role: "User", registrationDate: "2024-09-01" },
    { id: 22, name: "Tina Underwood", email: "tina@example.com", role: "Admin", registrationDate: "2024-10-01" },
    { id: 23, name: "Uma Vance", email: "uma@example.com", role: "User", registrationDate: "2024-11-01" },
    { id: 24, name: "Victor White", email: "victor@example.com", role: "User", registrationDate: "2024-12-01" },
    { id: 25, name: "Wendy Xander", email: "wendy@example.com", role: "Admin", registrationDate: "2025-01-01" },
  ]);
  const [editedUsers, setEditedUsers] = useState({});
  const [isSavingUser, setIsSavingUser] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showKycModal, setShowKycModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    const newEditedUsers = {};
    users.forEach((user) => {
      newEditedUsers[user.id] = { ...user, editing: false, saving: false };
    });
    setEditedUsers(newEditedUsers);
  }, [users]);

  const editUser = (user) => {
    const editedUser = editedUsers[user.id];
    editedUser.editing = true;
    setEditedUsers({
      ...editedUsers,
      [user.id]: editedUser,
    });
  };

  const editingUser = (user) => {
    return editedUsers[user.id] && editedUsers[user.id].editing;
  };

  const savingUser = (user) => {
    return editedUsers[user.id] && editedUsers[user.id].saving;
  };

  const cancelEditUser = (user) => {
    const oldUser = users.find((u) => u.id === user.id);
    setEditedUsers({
      ...editedUsers,
      [user.id]: { ...oldUser, editing: false, saving: false },
    });
  };

  const updateUser = (user) => {
    const editedUser = editedUsers[user.id];
    setIsSavingUser(true);
    // Simulate saving user
    setTimeout(() => {
      setUsers(users.map((u) => (u.id === user.id ? editedUser : u)));
      setIsSavingUser(false);
      setEditedUsers({
        ...editedUsers,
        [user.id]: { ...editedUser, editing: false, saving: false },
      });
    }, 1000);
  };

  const handleInputChange = (user, field, value) => {
    const editedUser = editedUsers[user.id];
    editedUser[field] = value;
    setEditedUsers({
      ...editedUsers,
      [user.id]: editedUser,
    });
  };

  const addUser = () => {
    setShowAddUserModal(true);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredUsers = users.filter((user) => {
    return (
      user.name.toLowerCase().includes(searchQuery) ||
      user.email.toLowerCase().includes(searchQuery) ||
      user.role.toLowerCase().includes(searchQuery) ||
      user.registrationDate.includes(searchQuery)
    );
  });

  const openKycModal = (user) => {
    setSelectedUser(user);
    setShowKycModal(true);
  };

  const closeKycModal = () => {
    setShowKycModal(false);
    setSelectedUser(null);
  };

  const handleKycUpload = (e) => {
    // Handle KYC document upload
    console.log("KYC document uploaded for user:", selectedUser);
    closeKycModal();
  };

  const onAddUser = (data) => {
    const newUser = {
      id: users.length + 1,
      name: data.firstName + " " + data.lastName,
      email: data.email,
      role: data.role,
      phone: data.phone,
      address: data.address,
      registrationDate: new Date().toISOString().split("T")[0],
    };
    setUsers([...users, newUser]);
    setShowAddUserModal(false);
    reset();
  };

  const closeAddUserModal = () => {
    setShowAddUserModal(false);
    reset();
  };

  return (
    <div className="client_users_page">
      <div className="heading">
        <div className="title">Client Users</div>
      </div>
      <div className="search_inputs">
        <div className="search_input">
          <div className="input search_query_container">
            <input
              className="search_query"
              type="text"
              placeholder="Search for users..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <Search className="icon" />
          </div>
        </div>
        <button onClick={addUser} className="add_user_button">
          Add User
        </button>
      </div>
      <div className="users_table_container">
        <table className="users_table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Registration Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>
                  {!editingUser(user) ? (
                    user.name
                  ) : (
                    <input
                      type="text"
                      value={editedUsers[user.id].name}
                      onChange={(e) =>
                        handleInputChange(user, "name", e.target.value)
                      }
                    />
                  )}
                </td>
                <td>
                  {!editingUser(user) ? (
                    user.email
                  ) : (
                    <input
                      type="email"
                      value={editedUsers[user.id].email}
                      onChange={(e) =>
                        handleInputChange(user, "email", e.target.value)
                      }
                    />
                  )}
                </td>
                <td>
                  {!editingUser(user) ? (
                    user.role
                  ) : (
                    <input
                      type="text"
                      value={editedUsers[user.id].role}
                      onChange={(e) =>
                        handleInputChange(user, "role", e.target.value)
                      }
                    />
                  )}
                </td>
                <td>
                  {!editingUser(user) ? (
                    user.registrationDate
                  ) : (
                    <input
                      type="date"
                      value={editedUsers[user.id].registrationDate}
                      onChange={(e) =>
                        handleInputChange(user, "registrationDate", e.target.value)
                      }
                    />
                  )}
                </td>
                <td>
                  <div className="user_controls">
                    <FileText
                      className="kyc user_control"
                      onClick={() => openKycModal(user)}
                    />
                    {!editingUser(user) && !savingUser(user) && (
                      <Pencil
                        className="edit user_control"
                        onClick={() => editUser(user)}
                      />
                    )}
                    {editingUser(user) && !savingUser(user) && (
                      <>
                        <Check
                          className="save_edit user_control"
                          onClick={() => updateUser(user)}
                        />
                        <X
                          className="cancel_edit user_control"
                          onClick={() => cancelEditUser(user)}
                        />
                      </>
                    )}
                    {savingUser(user) && (
                      <Loader2 className="saving_user_loader user_control" />
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showKycModal && (
        <div className="kyc_modal">
          <div className="kyc_modal_content">
            <h2>Upload KYC Documents for {selectedUser.name}</h2>
            <input type="file" onChange={handleKycUpload} />
            <button onClick={closeKycModal}>Close</button>
          </div>
        </div>
      )}
      {showAddUserModal && (
        <div className="add_user_modal">
          <div className="add_user_modal_content">
            <h2>Add New User</h2>
            <form onSubmit={handleSubmit(onAddUser)}>
              <div className="signup_input_group">
                <label className="signup_input">
                  <span className="signup_input_label">
                    First name <span className="required">*</span>
                  </span>
                  <input
                    placeholder="Enter your first name"
                    name="firstName"
                    className={cn({ error: errors.firstName?.type == "required" })}
                    {...register("firstName", { required: true })}
                  />
                  {errors.firstName?.type === "required" && (
                    <span className="signup_input_error">
                      <Info className="signup_input_error_icon" /> Please fill in your first name
                    </span>
                  )}
                </label>
                <div className="spacer"></div>
                <label className="signup_input">
                  <span className="signup_input_label">
                    Last name <span className="required">*</span>
                  </span>
                  <input
                    placeholder="Enter your last name"
                    name="lastName"
                    className={cn({ error: errors.lastName?.type == "required" })}
                    {...register("lastName", { required: true })}
                  />
                  {errors.lastName?.type === "required" && (
                    <span className="signup_input_error">
                      <Info className="signup_input_error_icon" /> Please fill in your last name
                    </span>
                  )}
                </label>
              </div>
              <div className="spacer"></div>
              <div className="signup_input_group">
                <label className="signup_input">
                  <span className="signup_input_label">
                    Email <span className="required">*</span>
                  </span>
                  <input
                    placeholder="Enter your email"
                    name="email"
                    className={cn({ error: errors.email?.type == "required" })}
                    {...register("email", { required: true })}
                  />
                  {errors.email?.type === "required" && (
                    <span className="signup_input_error">
                      <Info className="signup_input_error_icon" /> Please fill in your email
                    </span>
                  )}
                </label>
                <div className="spacer"></div>
                <label className="signup_input">
                  <span className="signup_input_label">
                    Role <span className="required">*</span>
                  </span>
                  <input
                    placeholder="Enter user role"
                    name="role"
                    className={cn({ error: errors.role?.type == "required" })}
                    {...register("role", { required: true })}
                  />
                  {errors.role?.type === "required" && (
                    <span className="signup_input_error">
                      <Info className="signup_input_error_icon" /> Please fill in the user role
                    </span>
                  )}
                </label>
              </div>
              <div className="spacer"></div>
              <div className="signup_input_group">
                <label className="signup_input">
                  <span className="signup_input_label">
                    Phone Number <span className="required">*</span>
                  </span>
                  <input
                    placeholder="Enter phone number"
                    name="phone"
                    className={cn({ error: errors.phone?.type == "required" })}
                    {...register("phone", { required: true })}
                  />
                  {errors.phone?.type === "required" && (
                    <span className="signup_input_error">
                      <Info className="signup_input_error_icon" /> Please fill in the phone number
                    </span>
                  )}
                </label>
                <div className="spacer"></div>
                <label className="signup_input">
                  <span className="signup_input_label">
                    Address <span className="required">*</span>
                  </span>
                  <input
                    placeholder="Enter address"
                    name="address"
                    className={cn({ error: errors.address?.type == "required" })}
                    {...register("address", { required: true })}
                  />
                  {errors.address?.type === "required" && (
                    <span className="signup_input_error">
                      <Info className="signup_input_error_icon" /> Please fill in the address
                    </span>
                  )}
                </label>
              </div>
              <div className="spacer"></div>
              <div className="spacer"></div>
              <div className="spacer"></div>
              <div className="signup_btn_box">
                <button type="submit" className="signup_btn">
                  Add User
                </button>
                <button type="button" className="cancel_btn" onClick={closeAddUserModal}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientUsers;