import { useState, useEffect } from 'react';
import api from '../../api';
import { useAuth } from "../../providers/AuthProvider";
import "./transactions.scss";
import { Loader2, Search, FileText } from "lucide-react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Transactions = () => {
  const { authTokens } = useAuth();
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterState, setFilterState] = useState("All");
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await api.getPendingTransactions({ token: authTokens.IdToken });
        if (Array.isArray(response.data.items)) {
          setTransactions(response.data.items);
        } else {
          console.error("Expected an array but got:", response.data);
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTransactions();
  }, [authTokens]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleFilterChange = (e) => {
    setFilterState(e.target.value);
  };

  const filteredTransactions = transactions.filter((transaction) => {
    const matchesSearchQuery =
      transaction.id.toString().includes(searchQuery) ||
      transaction.profileId.toLowerCase().includes(searchQuery) ||
      transaction.walletTxnAmount.toString().includes(searchQuery) ||
      transaction.createdOn.includes(searchQuery) ||
      transaction.walletTxnType.toLowerCase().includes(searchQuery) ||
      transaction.status.toLowerCase().includes(searchQuery);

    const matchesFilterState = filterState === "All" || transaction.status === filterState;

    return matchesSearchQuery && matchesFilterState;
  });

  const openTransactionModal = (transaction) => {
    setSelectedTransaction(transaction);
    setShowTransactionModal(true);
  };

  const closeTransactionModal = () => {
    setShowTransactionModal(false);
    setSelectedTransaction(null);
  };

  const getCountryFromCurrency = (currency) => {
    const currencyCountryMap = {
      NGN: "Nigeria",
      UGX: "Uganda",
      // Add more mappings as needed
    };
    return currencyCountryMap[currency] || "Unknown";
  };

  const formatMoney = (amount, currency) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency
    }).format(amount);
  };

  return (
    <div className="transactions_page">
      <div className="heading">
        <div className="title">Transactions</div>
      </div>
      <div className="search_inputs">
        <div className="search_input">
          <div className="input search_query_container">
            <input
              className="search_query"
              type="text"
              placeholder="Search for transactions..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <Search className="icon" />
          </div>
        </div>
        <div className="filter_state">
          <select value={filterState} onChange={handleFilterChange}>
            <option value="Pending">Pending</option>
            <option value="Completed">Completed</option>
            <option value="Approved">Approved</option>
            <option value="All">All</option>
          </select>
        </div>
      </div>
      <div className="transactions_table_container">
        {isLoading ? (
          <Loader2 className="loading_spinner" />
        ) : (
          <table className="transactions_table">
            <thead>
              <tr>
                <th>Country</th>
                <th>Currency</th>
                <th>Bank</th>
                <th>Date</th>
                <th>Amount</th>
                <th>TxId</th>
                <th>Status</th>
                <th>PayType</th>
              </tr>
            </thead>
            <tbody>
              {filteredTransactions.map((transaction) => (
                <tr key={transaction.id}>
                  <td>{getCountryFromCurrency(transaction.walletCurrency)}</td>
                  <td>{transaction.walletCurrency}</td>
                  <td>{transaction.paymentReference}</td>
                  <td>{new Date(transaction.createdOn).toLocaleDateString()}</td>
                  <td>{formatMoney(transaction.walletTxnAmount, transaction.walletCurrency)}</td>
                  <td>{transaction.id}</td>
                  <td>{transaction.status}</td>
                  <td>{transaction.paymentType}</td>
                  <td>
                    <div className="transaction_controls">
                      <FileText
                        className="view_transaction user_control"
                        onClick={() => openTransactionModal(transaction)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {showTransactionModal && (
        <div className="transaction_modal">
          <div className="transaction_modal_content">
            <h2>Transaction Details</h2>
            <div className="transaction_detail">
              <span className="transaction_label">Date:</span>
              <span className="transaction_value">{selectedTransaction.createdOn}</span>
            </div>
            <div className="transaction_detail">
              <span className="transaction_label">Transaction Type:</span>
              <span className="transaction_value">{selectedTransaction.walletTxnType}</span>
            </div>
            <div className="transaction_detail">
              <span className="transaction_label">Amount:</span>
              <span className="transaction_value">{selectedTransaction.walletTxnAmount}</span>
            </div>
            <div className="transaction_detail">
              <span className="transaction_label">Status:</span>
              <span className="transaction_value">{selectedTransaction.status}</span>
            </div>
            <div className="transaction_detail">
              <span className="transaction_label">Account Number:</span>
              <span className="transaction_value">{selectedTransaction.profileId}</span>
            </div>
            <div className="modal_buttons centered_buttons">
              <button onClick={closeTransactionModal}>Close</button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Transactions;