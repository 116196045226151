/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable react-refresh/only-export-components */
import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useReducer } from "react";
import api from "../../../api";
import { useAuth } from "../../../providers/AuthProvider";
import _ from "lodash";

const EscrowPendingContext = createContext();
const EscrowPendingDispatchContext = createContext();

export const ESCROW_PENDING_ACTIONS = {
	updateState: "updateState",
	updateStates: "updateStates",
};

export const EscrowPendingProvider = ({ children }) => {
	const { authTokens } = useAuth();

	const getEscrowPending = useQuery({
		queryKey: ["escrowPending"],
		queryFn: () => {
			return api.getEscrowPending({ token: authTokens.IdToken });
		},
		enabled: false,
	});

	const fetchEscrowPending = async () => {
		escrowPendingDispatch({ type: ESCROW_PENDING_ACTIONS.updateState, key: "isFetchingEscrowPending", value: true });
		const escrowPendingRefetchResult = await getEscrowPending.refetch();
		escrowPendingDispatch({ type: ESCROW_PENDING_ACTIONS.updateState, key: "isFetchingEscrowPending", value: false });
		if (escrowPendingRefetchResult.status == "success") {
			const requests = escrowPendingRefetchResult.data.data.data.attributes;
			const sortedRequests = _.sortBy(requests, ["createdAt"]).reverse();
			escrowPendingDispatch({ type: ESCROW_PENDING_ACTIONS.updateState, key: "requests", value: sortedRequests });
		}
	};

	const [escrowPendingState, escrowPendingDispatch] = useReducer(EscrowPendingReducer, {
		isFetchingEscrowPending: false,
		requests: [],
		fetchEscrowPending
	});



	

	//Get feaure flags
	useEffect(() => {
		(async () => {
			await fetchEscrowPending();
		})();
	}, []);

	return (
		<EscrowPendingContext.Provider value={escrowPendingState}>
			<EscrowPendingDispatchContext.Provider value={escrowPendingDispatch}>{children}</EscrowPendingDispatchContext.Provider>
		</EscrowPendingContext.Provider>
	);
};

export const useEscrowPending = () => {
	return useContext(EscrowPendingContext);
};

export const useEscrowPendingDispatch = () => {
	return useContext(EscrowPendingDispatchContext);
};

const EscrowPendingReducer = (escrowPendingState, action) => {
	switch (action.type) {
		case ESCROW_PENDING_ACTIONS.updateState: {
			const newState = { ...escrowPendingState };
			newState[action.key] = action.value;
			return newState;
		}
		case ESCROW_PENDING_ACTIONS.updateStates: {
			const newState = { ...escrowPendingState };
			action.states.forEach(({ key, value }) => {
				newState[key] = value;
			});
			return newState;
		}
		default: {
			throw Error("[EscrowPendingReducer] Unknown action: " + action.type);
		}
	}
};
