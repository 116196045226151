/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable react-refresh/only-export-components */
import { useMutation, useQuery } from "@tanstack/react-query";
import { createContext, useContext, useReducer } from "react";
import api from "../../../api";
import { useAuth } from "../../../providers/AuthProvider";
import { toast } from "react-toastify";
import { formatAmount } from "../../../common/formatAmount";

const EscrowedRequestContext = createContext();
const EscrowedRequestDispatchContext = createContext();

export const ESCROWED_REQUEST_ACTIONS = {
	updateState: "updateState",
	updateStates: "updateStates",
};

export const POP_TYPES = {
	BUYER_POP: "BUYER_POP",
	ESCROW_BUYER_POP: "ESCROW_BUYER_POP",
	SELLER_POP: "SELLER_POP",
	ESCROW_SELLER_POP: "ESCROW_SELLER_POP",
};

export const PAYMENT_METHOD_TYPES = {
	BANK_ACCOUNT: "BANK_ACCOUNT",
	PHONE_NUMBER: "PHONE_NUMBER",
	WALLET_ADDRESS: "WALLET_ADDRESS",
};

export const EscrowedRequestProvider = ({ children }) => {
	const { authTokens } = useAuth();

	const getEscrowedRequest = useQuery({
		queryKey: ["escrowedRequest"],
		queryFn: () => {
			return api.getEscrowedRequest({ token: authTokens.IdToken, id: escrowedRequestState.activeRequestId });
		},
		enabled: false,
	});

	const refreshActiveRequest = async ({ background } = { background: false }) => {
		if (!background) {
			escrowedRequestDispatch({ type: ESCROWED_REQUEST_ACTIONS.updateState, key: "isFetchingEscrowedRequest", value: true });
		}
		const escrowedRequestRefetchResult = await getEscrowedRequest.refetch();
		if (escrowedRequestRefetchResult.status == "success") {
			const activeRequest = escrowedRequestRefetchResult.data.data.data.attributes;
			const incomingSellerPops = [];
			const outgoingSellerPops = [];
			const incomingBuyerPops = [];
			const outgoingBuyerPops = [];

			// Split PoPs
			for (let i = 0; i < activeRequest._proofOfPayments.length; i++) {
				const pop = activeRequest._proofOfPayments[i];
				switch (pop.popType) {
					case POP_TYPES.SELLER_POP:
						incomingSellerPops.push(pop);
						break;
					case POP_TYPES.ESCROW_BUYER_POP:
						outgoingSellerPops.push(pop);
						break;
					case POP_TYPES.BUYER_POP:
						incomingBuyerPops.push(pop);
						break;
					case POP_TYPES.ESCROW_SELLER_POP:
						outgoingBuyerPops.push(pop);
						break;
				}
			}

			console.log({ incomingSellerPops, outgoingSellerPops, incomingBuyerPops, outgoingBuyerPops });

			escrowedRequestDispatch({
				type: ESCROWED_REQUEST_ACTIONS.updateStates,
				states: [
					{ key: "activeRequest", value: activeRequest },
					{ key: "incomingSellerPops", value: incomingSellerPops },
					{ key: "outgoingSellerPops", value: outgoingSellerPops },
					{ key: "incomingBuyerPops", value: incomingBuyerPops },
					{ key: "outgoingBuyerPops", value: outgoingBuyerPops },
				],
			});
		}

		if (!background) {
			escrowedRequestDispatch({ type: ESCROWED_REQUEST_ACTIONS.updateState, key: "isFetchingEscrowedRequest", value: false });
		}
	};

	const apiRejectPoP = useMutation({
		mutationFn: async ({ pop }) => {
			console.log({ pop });
			console.log(`Reject PoP..`);
			const updatingPops = [...escrowedRequestState.updatingPops];
			updatingPops.push(pop.id);
			escrowedRequestDispatch({ type: ESCROWED_REQUEST_ACTIONS.updateState, key: "updatingPops", value: updatingPops });

			const token = authTokens.IdToken;
			const request = escrowedRequestState.activeRequest;
			const order = request.order;

			return api.rejectProofOfPayment({ token, pop, order, request });
		},
		onError: (error, variables, context) => {
			const { pop } = variables;
			const updatingPops = escrowedRequestState.updatingPops.filter((id) => id != pop.id);
			escrowedRequestDispatch({ type: ESCROWED_REQUEST_ACTIONS.updateState, key: "updatingPops", value: updatingPops });
			toast.error("Error Rejecting PoP !", {
				position: "top-right",
			});
		},
		onSuccess: async (data, variables, context) => {
			const { pop } = variables;
			// refresh pops
			await refreshActiveRequest({ background: true });
			// remove pop id from updating pops
			const updatingPops = escrowedRequestState.updatingPops.filter((id) => id != pop.id);
			escrowedRequestDispatch({ type: ESCROWED_REQUEST_ACTIONS.updateStates, states: [{ key: "updatingPops", value: updatingPops }] });
			toast.success("PoP Rejected !");
		},
	});

	const rejectPop = async (pop) => {
		await apiRejectPoP.mutate({ pop });
	};

	const apiVerifyPoP = useMutation({
		mutationFn: async ({ pop }) => {
			console.log({ pop });
			console.log(`Reject PoP..`);
			const updatingPops = [...escrowedRequestState.updatingPops];
			updatingPops.push(pop.id);
			escrowedRequestDispatch({ type: ESCROWED_REQUEST_ACTIONS.updateState, key: "updatingPops", value: updatingPops });

			const token = authTokens.IdToken;
			const request = escrowedRequestState.activeRequest;
			const order = request.order;

			return api.verifyProofOfPayment({ token, pop, order, request });
		},
		onError: (error, variables, context) => {
			const { pop } = variables;
			const updatingPops = escrowedRequestState.updatingPops.filter((id) => id != pop.id);
			escrowedRequestDispatch({ type: ESCROWED_REQUEST_ACTIONS.updateState, key: "updatingPops", value: updatingPops });
			toast.error(`Error Verifying PoP (${formatAmount(pop.amount)} ${pop.currency})!`, {
				position: "top-right",
			});
		},
		onSuccess: async (data, variables, context) => {
			const { pop } = variables;
			// refresh pops
			await refreshActiveRequest({ background: true });
			// remove pop id from updating pops
			const updatingPops = escrowedRequestState.updatingPops.filter((id) => id != pop.id);
			escrowedRequestDispatch({ type: ESCROWED_REQUEST_ACTIONS.updateStates, states: [{ key: "updatingPops", value: updatingPops }] });
			toast.success("PoP Verified !");
		},
	});
	
	const verifyPop = async (pop) => {
		await apiVerifyPoP.mutate({ pop });
	};

	const [escrowedRequestState, escrowedRequestDispatch] = useReducer(EscrowedRequestReducer, {
		refreshActiveRequest,
		rejectPop,
		verifyPop,
		isFetchingEscrowedRequest: false,
		back: "",
		activeRequestId: "",
		activeRequest: {},
		incomingSellerPops: [], // SELLER POPS
		outgoingSellerPops: [], // ESCROW SELLER POPS
		incomingBuyerPops: [], // BUYER POPS
		outgoingBuyerPops: [], // ESCROW BUYER POPS
		updatingPops: [], // POPS being updated ( rejected / verified )
	});

	return (
		<EscrowedRequestContext.Provider value={escrowedRequestState}>
			<EscrowedRequestDispatchContext.Provider value={escrowedRequestDispatch}>{children}</EscrowedRequestDispatchContext.Provider>
		</EscrowedRequestContext.Provider>
	);
};

export const useEscrowedRequest = () => {
	return useContext(EscrowedRequestContext);
};

export const useEscrowedRequestDispatch = () => {
	return useContext(EscrowedRequestDispatchContext);
};

const EscrowedRequestReducer = (escrowedRequestState, action) => {
	switch (action.type) {
		case ESCROWED_REQUEST_ACTIONS.updateState: {
			const newState = { ...escrowedRequestState };
			newState[action.key] = action.value;
			return newState;
		}
		case ESCROWED_REQUEST_ACTIONS.updateStates: {
			const newState = { ...escrowedRequestState };
			action.states.forEach(({ key, value }) => {
				newState[key] = value;
			});
			return newState;
		}
		default: {
			throw Error("[EscrowedRequestReducer] Unknown action: " + action.type);
		}
	}
};
