import React from "react";
import ReactDOM from "react-dom/client";
import "./main.scss";
import SystemProviders from "./providers/SystemProviders";
import Login from "./login/login.jsx";
import Workspace from "./workspace/workspace";
import workspaceRoutes from "./workspace/workspaceRoutes";
import NotFound from "./not_found/notFound.jsx";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

//common
import "./common/transactions_table.scss";

//notification library
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Workspace />,
		errorElement: <Workspace />,
		children: workspaceRoutes,
	},
	{
		path: "/login",
		element: <Login />,
	},

	{
		path: "*",
		element: <NotFound />,
	},
]);

ReactDOM.createRoot(document.getElementById("root")).render(
	<React.StrictMode>
		<SystemProviders>
			<RouterProvider router={router} />
			<ToastContainer />
		</SystemProviders>
	</React.StrictMode>
);
