/* eslint-disable react/prop-types */
import  { createContext, useContext, useState } from 'react';

// Create context
const CurrencyFiltersContext = createContext();

// Create provider
export const CurrencyFiltersProvider = ({ children }) => {
  const [filters, setFilters] = useState({
    searchQuery: '',
    lastUpdated: null,
    PriceToUSD: null,
    currency: null,
  });

  const updateFilters = (newFilters) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  return (
    <CurrencyFiltersContext.Provider value={{ filters, updateFilters }}>
      {children}
    </CurrencyFiltersContext.Provider>
  );
};

// Custom hook to use filters
export const useCurrencyFilters = () => {
  return useContext(CurrencyFiltersContext);
};
