import Big from "big.js";
import { formatAmount } from "../../../../common/formatAmount";
import "./escrowedRequestDetails.scss";
import _ from "lodash";
import { useEscrowedRequest } from "../escrowedRequestProvider";

const EscrowedRequestDetails = () => {
	const { activeRequest } = useEscrowedRequest();

	const getSelling = ({ request }) => {
		if (_.isEmpty(request)) return;

		const currency = request.order.sellCurrency;
		const rate = request.order.volumeTypeAmount;
		let amount;
		if (!_.isEmpty(`${request.desiredPurchaseAmount}`) && !_.isEmpty(rate)) {
			amount = formatAmount(Big(request.desiredPurchaseAmount).div(Big(rate)).toString());
		}
		const selling = `${amount} ${currency}`;
		return <p>{selling}</p>;
	};

	const getBuying = ({ request }) => {
		if (_.isEmpty(request)) return;

		const currency = request.order.buyCurrency;
		const amount = formatAmount(request.desiredPurchaseAmount);
		const buying = `${amount} ${currency}`;
		return <p>{buying}</p>;
	};

	const getExchangeRate = ({ request }) => {
		if (_.isEmpty(request)) return;
		const sellCurrency = request.order.sellCurrency;
		const buyCurrency = request.order.buyCurrency;
		const buyRate = formatAmount(request.order.volumeTypeAmount);
		const sellRate = formatAmount(Big(1).div(Big(request.order.volumeTypeAmount)).toString());
		return (
			<>
				<p>
					1 {sellCurrency} = {buyRate} {buyCurrency}
				</p>
				<p>
					1 {buyCurrency} = {sellRate} {sellCurrency}
				</p>
			</>
		);
	};

	return (
		<div id="escrowed_request_details_component">
			<div className="info_box">
				<div className="info">
					<div className="label">Selling</div>
					<div className="value">{getSelling({ request: activeRequest })}</div>
				</div>
				<div className="info">
					<div className="label">Seller</div>
					<div className="value">{activeRequest?.order?.createdByName}</div>
				</div>
				<div className="info">
					<div className="label">Seller payment methods</div>
					<div className="value"></div>
				</div>
			</div>
			<div className="spacer_box">
				<hr className="spacer" />
			</div>
			<div className="info_box">
				<div className="info">
					<div className="label">Buying</div>
					<div className="value">{getBuying({ request: activeRequest })}</div>
				</div>
				<div className="info">
					<div className="label">Buyer</div>
					<div className="value">{activeRequest?.createdByName}</div>
				</div>
				<div className="info">
					<div className="label">Buyer payment methods</div>
					<div className="value"></div>
				</div>
			</div>
			<div className="spacer_box">
				<hr className="spacer" />
			</div>
			<div className="info_box">
				<div className="info">
					<div className="label">Price</div>
					<div className="value">{getExchangeRate({ request: activeRequest })}</div>
				</div>
			</div>
		</div>
	);
};
export default EscrowedRequestDetails;
