import "./fees.scss";

const Fees = () => {
	return (
		<div className="fees_page">
			<div className="heading">
				<div className="title">Fees</div>
			</div>
		</div>
	);
};
export default Fees;
