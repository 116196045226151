/* eslint-disable no-unused-vars */
/* eslint-disable react-refresh/only-export-components */
/* eslint-disable react/prop-types */
import { useMutation, useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useReducer } from "react";
import { useAuth } from "../AuthProvider";
import api from "../../api";
import Big from "big.js";
import { currencies } from "./currencies";
import _ from "lodash";

const RatesContext = createContext();
const RatesDispatchContext = createContext();

export const CURRENCY_TYPES = {
	FIAT: "FIAT",
	CRYPTO: "CRYPTO",
};

export const RATES_ACTIONS = {
	updateState: "updateState",
	updateStates: "updateStates",
	fetchedPairPrices: "fetchedPairPrices",
};

export const RatesProvider = ({ children }) => {
	const { authTokens, isLoggedIn } = useAuth();

	const getRates = useQuery({
		queryKey: ["rates"],
		queryFn: () => {
			ratesDispatch({
				type: "fetchingRates",
			});
			return api.getRates();
		},
		enabled: true,
		onSuccess: (data) => {
            // Dispatch fetched rates after a successful fetch
            ratesDispatch({
                type: "fetchedRates",
                rates: data.data.data.attributes,
            });
        },
	});

	const getPairPrices = useQuery({
		queryKey: ["pairprices"],
		queryFn: () => {
			ratesDispatch({ type: RATES_ACTIONS.updateState, key: "isLoadingPairPrices", value: true });
			return api.getPairPrices({ token: authTokens.IdToken });
		},
		enabled: false,
	});

	const updateRate = useMutation({
		mutationFn: async ({ token, code, rate }) => {
			ratesDispatch({ type: RATES_ACTIONS.updateState, key: "isSavingRate", value: true });
			await api.updateRate({ token, code, rate });
			const ratesRefetchResult = await api.getRates();
			if (ratesRefetchResult.status == "success") {
				const newRates = ratesRefetchResult.data.data.data.attributes;
				ratesDispatch({
					type: "fetchedRates",
					rates: newRates,
				});
			}

			return ratesRefetchResult;
		},
		onError: (error, variables, context) => {
			ratesDispatch({ type: RATES_ACTIONS.updateState, key: "isSavingRate", value: false });
		},
		onSuccess: async () => {
			ratesDispatch({ type: RATES_ACTIONS.updateState, key: "isSavingRate", value: false });
		},
	});

	const updatePairPrice = useMutation({
		mutationFn: async ({ token, pair, markup, markdown, enabled }) => {
			ratesDispatch({ type: RATES_ACTIONS.updateState, key: "isSavingPairPrice", value: true });
			await api.updatePairPrice({ token, pair, markup, markdown, enabled });
			const pairpricesRefetchResult = await api.getPairPrices();
			if (pairpricesRefetchResult.status == "success") {
				const newPairPrices = pairpricesRefetchResult.data.data.data.attributes;
				ratesDispatch({
					type: "fetchedPairPrices",
					pairprices: newPairPrices,
				});
			}

			return pairpricesRefetchResult;
		},
		onError: (error, variables, context) => {
			ratesDispatch({ type: RATES_ACTIONS.updateState, key: "isSavingPairPrice", value: false });
		},
		onSuccess: async () => {
			ratesDispatch({ type: RATES_ACTIONS.updateState, key: "isSavingPairPrice", value: false });
		},
	});

	const fiatCurrencies = ["USD", "UGX", "KES", "NGN", "TZS", "ETB", "GHS", "AED", "GBP", "ZMW", "XAF", "XOF"];
	const cryptoCurrencies = ["BTC", "ETH", "USDT", "USDC", "TRX"];

	const supportedCurrencies = [];
	supportedCurrencies.push(...fiatCurrencies);
	supportedCurrencies.push(...cryptoCurrencies);

	const filteredCurrencies = supportedCurrencies.map((supportedCurrency) => _.find(currencies, { code: supportedCurrency }));
	

	const getRate = ({ rates, from, to }) => {
		if (!rates || !rates.rates || !rates.rates[from] || !rates.rates[to]) {
			return "0"; // Default value if rates are not available
		}
		let rate = "";
		// If `from` currency == rates.base, return the basic exchange rate for the `to` currency
		if (from == rates.base) {
			rate = Big(rates.rates[to]).toString();
			return rate;
		}

		// If `to` currency === fx.base, return the basic inverse rate of the `from` currency
		if (to === rates.base) {
			rate = Big(1).div(Big(rates.rates[from])).toString();
			return rate;
		}

		// Otherwise, return the `to` rate multipled by the inverse of the `from` rate to get the
		// relative exchange rate between the two currencies
		rate = Big(rates.rates[to])
			.mul(Big(1).div(Big(rates.rates[from])))
			.toString();

		return rate;
	};

	const convert = ({ rates, from, to, amount }) => {
		const rate = getRate({ rates, from, to });
		const newAmount = Big(amount).mul(Big(rate)).toString();
		return newAmount;
	};

	const isFiat = new Set();

	const isCrypto = new Set();

	const saveRate = async ({ token, code, rate }) => {
		await updateRate.mutate({ token, code, rate });
	};

	const savePairPrice = async ({ token, pair, markup, markdown, enabled }) => {
		await updatePairPrice.mutate({ token, pair, markup, markdown, enabled });
	};

	const [ratesState, ratesDispatch] = useReducer(ratesReducer, {
		rates: {},
		lastUpdatedAt: {},
		isLoadingRates: false,
		getRate,
		convert,
		currencies: filteredCurrencies,
		isFiat,
		isCrypto,
		saveRate,
		isSavingRate: false,
		pairprices: [],
		isLoadingPairPrices: false,
		savePairPrice,
	});

	//get rates
	useEffect(() => {
		(async () => {
			const ratesRefetchResult = await getRates.refetch();
			if (ratesRefetchResult.status == "success") {
				const newRates = ratesRefetchResult.data.data.data.attributes;
				ratesDispatch({
					type: "fetchedRates",
					rates: newRates,
				});
			}

			const pairPricesRefetchResult = await getPairPrices.refetch();
			if (pairPricesRefetchResult.status == "success") {
				const newPairPrices = pairPricesRefetchResult.data.data.data.attributes;
				ratesDispatch({
					type: RATES_ACTIONS.fetchedPairPrices,
					pairprices: newPairPrices,
				});
			}
		})();
	}, [isLoggedIn]);

	return (
		<RatesContext.Provider value={ratesState}>
			<RatesDispatchContext.Provider value={ratesDispatch}>{children}</RatesDispatchContext.Provider>
		</RatesContext.Provider>
	);
};

export const useRates = () => {
	return useContext(RatesContext);
};

export const useRatesDispatch = () => {
	return useContext(RatesDispatchContext);
};

const ratesReducer = (ratesState, action) => {
	switch (action.type) {
		case "fetchedRates": {
			// Convert rates to appropriate format
			const rates = {
				base: "USD",
				rates: {},
			};

			const isFiat = new Set();

			const isCrypto = new Set();

			const lastUpdatedAt = {};

			Object.keys(action.rates.rates.cryptocurrency).forEach((crypto) => {
				isCrypto.add(crypto);
				rates.rates[crypto] = action.rates.rates.cryptocurrency[crypto].rate;
				lastUpdatedAt[crypto] = action.rates.rates.cryptocurrency[crypto].updatedAt || action.rates.updatedAt;
			});

			Object.keys(action.rates.rates.fiat).forEach((fiat) => {
				isFiat.add(fiat);
				rates.rates[fiat] = action.rates.rates.fiat[fiat].rate;
				lastUpdatedAt[fiat] = action.rates.rates.fiat[fiat].updatedAt || action.rates.updatedAt;
			});

			const getCurrencyType = (code) => {
				let currencyType;

				if (isFiat.has(code)) {
					currencyType = CURRENCY_TYPES.FIAT;
				}

				if (isCrypto.has(code)) {
					currencyType = CURRENCY_TYPES.CRYPTO;
				}

				return currencyType;
			};

			return { ...ratesState, rates, isLoadingRates: false, isFiat, isCrypto, getCurrencyType, lastUpdatedAt };
		}
		case "fetchingRates":
			return { ...ratesState, isLoadingRates: true };
		case RATES_ACTIONS.fetchedPairPrices: {
			const newRatesState = { ...ratesState };
			let pairprices = [];

			Object.keys(action.pairprices.pairprices).forEach((pair) => {
				
				//Determine from and to depending on rate value being > 1;
				const { getRate, rates, currencies } = ratesState;
				const codeTokens = pair.split("/");
			

				const rate1 = getRate({
					rates,
					from: codeTokens[0],
					to: codeTokens[1],
				});

				const rate2 = getRate({
					rates,
					from: codeTokens[1],
					to: codeTokens[0],
				});

				const pairprice = action.pairprices.pairprices[pair];
				const from = {};
				const to = {};
				let referencePrice;
				if (rate1 > 1) {
					from.code = codeTokens[0];
					to.code = codeTokens[1];
					referencePrice = rate1;
				} else {
					from.code = codeTokens[1];
					to.code = codeTokens[0];
					referencePrice = rate2;
				}

				from.logo = _.find(currencies, { code: from.code })?.logo;
				to.logo = _.find(currencies, { code: to.code })?.logo;
				
				const markdown = parseFloat(pairprice.markdown) || 0;
				const markup = parseFloat(pairprice.markup) || 0;
				
				
				
				const buyPrice = Big(referencePrice)
					.sub(Big(referencePrice).mul(Big(markdown).div(Big(100))))
					.toString();
				
				const sellPrice = Big(referencePrice)
					.add(Big(referencePrice).mul(Big(markup).div(Big(100))))
					.toString();

				pairprice.from = from;
				pairprice.to = to;
				pairprice.referencePrice = referencePrice;
				pairprice.buyPrice = buyPrice;
				pairprice.sellPrice = sellPrice;
				pairprice.pair = pair;

				pairprices.push(pairprice);
			});

			return { ...newRatesState, pairprices, isLoadingPairPrices: false };
		}
		case RATES_ACTIONS.updateState: {
			const newRatesState = { ...ratesState };
			newRatesState[action.key] = action.value;
			return newRatesState;
		}
		case RATES_ACTIONS.updateStates: {
			const newRatesState = { ...ratesState, ...action.states };
			return newRatesState;
		}
		default: {
			throw Error("[ratesReducer] Unknown action: " + action.type);
		}
	}
};
